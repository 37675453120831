import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { FAMILY_MEMBER_HISTORY_STATUS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { v4 as uuidv4 } from 'uuid';
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";

const FamilyMemberHistory = ({ open, onConfirm, close }) => {

    const validate = (values) => {
        const errors = {};
        if (values.fmh_Status.value === "Select Status") {
            errors.fmh_Status = "Status is required.";
        }
        if(!values.relationship){
            errors.relationship = "Please provide relation ship to the subject."
        }
        if(!values.fmh_condition){
            errors.fmh_condition = "Please provide the condition."
        }

        return errors;
    };

    return (
        <>
            <Formik
                initialValues={{
                    fmh_Status:{ label: "Select Status", value: "Select Status" },
                    relationship:"",
                    fmh_reason:"",
                    fmh_condition:""
                }}
                validate={validate}
                onSubmit={(values) => {
                    onConfirm((prev) => [
                        ...prev,
                        {
                            type: `FMH ${prev.length + 1}`,
                            data: {
                                resourceType: "FamilyMemberHistory",
                                id:uuidv4(),
                                status: values.fmh_Status.value,
                                relationship: {
                                    text: values.relationship,
                                },
                                reasonCode: {
                                    text: values.fmh_reason
                                },
                                condition: {
                                    code:{
                                        text: values.fmh_condition,
                                    }
                                }
                            }
                        }
                    ]);
                    close();
                }}
            >
                {({ values, handleSubmit, setFieldValue, handleBlur }) => (
                    <ModalComponent
                        open={open}
                        title={"Add Family Member History"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={handleSubmit}
                            >
                                Submit
                            </Button>
                        }
                        close={() => close()}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                        customBodyClasses="overflow-visible" 
                    >
                        <form onSubmit={handleSubmit} className="z-1 flex flex-col gap-4 ">
                            <div className="grid grid-cols-2 gap-4 mt-2">
                                <SelectDropdown
                                    label="Select Status"
                                    isRequired={true}
                                    name="fmh_Status"
                                    customClasses="w-full z-50"
                                    value={values.fmh_Status}
                                    options={[{ label: "Select Status", value: "Select Status" }, ...FAMILY_MEMBER_HISTORY_STATUS]}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue("fmh_Status", selectedOption)
                                    }
                                />

                                <Input
                                    label="Relationship"
                                    placeholder="Relationship to the subject"
                                    name="relationship"
                                    isRequired={true}
                                    value={values.relationship}
                                    onChangeCb={(e) => setFieldValue("relationship", e.target.value)}
                                    onBlurCb={handleBlur}
                                />
                                <Input
                                    label="Reason"
                                    placeholder="Enter reason"
                                    name={"fmh_reason"}
                                    value={values.fmh_reason}
                                    onChangeCb={(e) => {
                                        setFieldValue("fmh_reason", e.target.value );
                                    }}
                                />
                                <Input
                                    label="Condition Code"
                                    placeholder="Enter Condition suffered by relation"
                                    name={"fmh_condition"}
                                    isRequired={true}
                                    value={values.fmh_condition}
                                    onChangeCb={(e) => {
                                        setFieldValue("fmh_condition", e.target.value );
                                    }}
                                />
                            </div>
                        </form>
                    </ModalComponent>
                )}
            </Formik>
        </>
    );
};

export default FamilyMemberHistory;
