import React from "react";
import { FieldRow, formatResourceType } from "./FHIRBundleViewer";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";

export const CarePlan = ({ resource, bundle }) => {
    const entries = bundle?.entry || [];
    const { status, intent, category, goal } = resource;

    const getResourceByReference = (referenceId) => {
        const entry = entries.find((entry) => entry.fullUrl === referenceId);
        return entry ? entry.resource : null;
    };
    const goalList = goal?.map((item) => getResourceByReference(item.reference)) || [];
    return (
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md border border-gray-300">
            <Heading type={HEADING.H3}className="text-xl font-bold text-gray-800 mb-4">
                {formatResourceType(resource.resourceType)}
            </Heading>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-2">
                <FieldRow label="Status" value={status} />
                <FieldRow label="Intent" value={intent} />
                <FieldRow label="Category" value={category[0]?.text} />
            </div>
            {goalList?.length > 0 && (
                <div className="">
                <Heading type={HEADING.H3} className="text-lg font-semibold mb-2 border-b-2 border-gray-200">
                    Goals
                </Heading>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    {goalList.map((goalItem) => (
                    <div key={goalItem.id} className="p-4 rounded-lg border border-gray-200">
                        <FieldRow label="Description" value={goalItem?.description?.text} />
                        <FieldRow label="Lifecycle Status" value={goalItem?.lifecycleStatus} />
                        {goalItem?.target && goalItem.target?.length > 0 && (
                        <div className="mt-4 pl-4 border-l border-gray-300">
                            <Heading type={HEADING.H4} className="text-md font-medium text-gray-600 mb-2">Target</Heading>
                            <div className="space-y-2">
                            {goalItem.target.map((target, idx) => (
                                <div key={idx} className="p-2 bg-white rounded border border-gray-200">
                                {target.measure && (
                                    <FieldRow
                                    label="Measure"
                                    value={
                                        target.measure.text ||
                                        target.measure.coding?.[0]?.display
                                    }
                                    />
                                )}
                                </div>
                            ))}
                            </div>
                        </div>
                        )}
                    </div>
                    ))}
                </div>
                </div>
            )}
        </div>
      );
}