import React from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import Button from '../../../../../components/common-components/button/Button';
import ModalComponent from '../../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../../components/icons/Icons';
import Input from '../../../../../components/common-components/input/Input';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import TextArea from '../../../../../components/common-components/textArea/TextArea';
import { ADDRESS_USE_OPTIONS, FORM_FIELDS_FOR_ORGANIZATION, TELECOM_SYSTEM_OPTIONS, TELECOM_USE_OPTIONS } from '../Constants';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';

const AddTelecomOrAddress = ({ close, isTelecomPopupOpen, isAddressPopupOpen, setTelecomList, setAddressList }) => {
    const fields = [
        ...(isTelecomPopupOpen ? [{fieldName: FORM_FIELDS_FOR_ORGANIZATION.TELECOM_VALUE, isRequired: true}]: [{}]),
        ...(isAddressPopupOpen ? [{fieldName: FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_TEXT, isRequired: true}]: [{}]),
    ];
    const validationSchema = getValidationSchema(fields);

	return (
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_ORGANIZATION.TELECOM_VALUE]:"",
                [FORM_FIELDS_FOR_ORGANIZATION.TELECOM_SYSTEM]:"",
                [FORM_FIELDS_FOR_ORGANIZATION.TELECOM_USE]:"",
                [FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_TEXT]:"",
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                let data;
                if(isTelecomPopupOpen){
                    data = {
                        system: values[FORM_FIELDS_FOR_ORGANIZATION.TELECOM_SYSTEM]?.value,
                        use: values[FORM_FIELDS_FOR_ORGANIZATION.TELECOM_USE]?.value,
                        value: values[FORM_FIELDS_FOR_ORGANIZATION.TELECOM_VALUE]
                    }
                    setTelecomList((prev) => [...prev, {type: `Telecom ${prev.length + 1}`, data: data}])
                }else {
                    data = {
                        use: values[FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_USE]?.value,
                        text: values[FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_TEXT]
                    }
                    setAddressList((prev) => [...prev, {type: `Address ${prev.length + 1}`, data: data}])
                }
                close();
            }}
        >	
            {({ values, errors, setFieldValue, handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <ModalComponent
                            open={isTelecomPopupOpen || isAddressPopupOpen}
                            title={`${isTelecomPopupOpen ? "Add Telecom": "Add Address"}`}
                            footerButton={
                                <Button
                                    variant={BUTTON_VARIANTS.CONTAINED}
                                    type="submit"
                                    customBtnClass="text-met-primary"
                                >
                                    Submit
                                </Button>
                            }
                            icon={<Icons iconName="closeIcon" />}
                            close={() => close()}
                            customClasses="w-[800px] w-[90%]"
                            customBodyClasses="overflow-visible"
                        >
                            <>
                                {isTelecomPopupOpen ? (
                                    <div className="grid grid-cols-2 gap-4">
                                        <SelectDropdown
                                            label={"System"}
                                            name={FORM_FIELDS_FOR_ORGANIZATION.TELECOM_SYSTEM}
                                            customClasses="w-full"
                                            placeholder="Select"
                                            value={values[FORM_FIELDS_FOR_ORGANIZATION.TELECOM_SYSTEM]}
                                            options={TELECOM_SYSTEM_OPTIONS}
                                            onChangeCb={(selectedOption) =>
                                                setFieldValue(FORM_FIELDS_FOR_ORGANIZATION.TELECOM_SYSTEM, selectedOption)
                                            }
                                        />
                                        <SelectDropdown
                                            label={"Use"}
                                            name={FORM_FIELDS_FOR_ORGANIZATION.TELECOM_USE}
                                            customClasses="w-full"
                                            placeholder="Select"
                                            value={values[FORM_FIELDS_FOR_ORGANIZATION.TELECOM_USE]}
                                            options={TELECOM_USE_OPTIONS}
                                            onChangeCb={(selectedOption) =>
                                                setFieldValue(FORM_FIELDS_FOR_ORGANIZATION.TELECOM_USE, selectedOption)
                                            }
                                        />
                                        <div>
                                            <Input
                                                label="Value"
                                                placeholder="The actual contact point details"
                                                name={FORM_FIELDS_FOR_ORGANIZATION.TELECOM_VALUE}
                                                isRequired={true}
                                                value={values[FORM_FIELDS_FOR_ORGANIZATION.TELECOM_VALUE]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(FORM_FIELDS_FOR_ORGANIZATION.TELECOM_VALUE, e.target.value)
                                                }}
                                            />
                                        </div>
                                        
                                    </div>
                                ) : (
                                    <>
                                        <SelectDropdown
                                            label={"Use"}
                                            name={FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_USE}
                                            customClasses="w-full"
                                            placeholder="Select"
                                            value={values[FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_USE]}
                                            options={ADDRESS_USE_OPTIONS}
                                            onChangeCb={(selectedOption) =>
                                                setFieldValue(FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_USE, selectedOption)
                                            }
                                        />
                                        <TextArea
                                            label={"Address"}
                                            isRequired={true}
                                            name={FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_TEXT}
                                            value={values[FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_TEXT]}
                                            onChangeCb={(e) => {
                                                setFieldValue(FORM_FIELDS_FOR_ORGANIZATION.ADDRESS_TEXT, e.target.value)
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        </ModalComponent>
                    </Form>
                );
            }}
        </Formik>
	);
};

export default AddTelecomOrAddress;
