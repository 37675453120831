import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import { addNotifications } from "../../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../../components/common-components/toaster/MetToaster";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { SandClock } from "../AddRecords/GenerateTokenPopup";
import Icons from "../../../../../components/icons/Icons";
import SuccesStepIcon from "../../../../../components/icons/vectors/SuccesStepIcon";
import Label from "../../../../../components/common-components/label/Label";
import { componentKey as PatientDashboardComponentKey, setDecryptedData, setHUInotifyData } from "../../PatientDashboardSlice";
import { consentRequestStatus } from "../../PatientDashboardSaga";
import LoadingIcon from "../../../../../components/icons/vectors/LoadingIcon";
import WarningIcon from "../../../../../components/icons/vectors/WarningIcon";

const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

function ConsentStatusPopup({ close, onConfirm }) {
    const { consentRequestData, huiNotifyData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [isAccessRequested, setIsAccessRequested] = useState(false);
    const socketRef = useRef(null);
    const dispatch = useDispatch();

    const handleGenerate = async () => {
        const payload = { consentId: consentRequestData.consentRequest.id };
        dispatch(consentRequestStatus(payload));
    };

    useEffect(() => {
        if(huiNotifyData){
            stopRequestStatusEmitting()
        }
    },[huiNotifyData])

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(socketURL);
        }
        const socket = socketRef.current;

        socket.on("connect", () => {
            startRequestStatusEmitting();
            setTimeout(async () => {
                await handleGenerate();
            }, 2000);
        });

        socket.off("getConsentRequestStatus").on("getConsentRequestStatus", (data) => {
            if (data) {
                console.log("socket data (getConsentRequestStatus)", data);
                setIsAccessRequested(true);
                dispatch(addNotifications({ message: "Consent Status Requested", variant: TOASTER_VARIANT.SUCCESS }));
            }
        });

        return () => {
            if (socket) {
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    const startRequestStatusEmitting = () => socketRef.current?.emit("getConsentRequestStatus");

    const stopRequestStatusEmitting = () => socketRef.current?.emit("getConsentRequestStatus", { action: "stop" });

    const allStepsCompleted = isAccessRequested && huiNotifyData;

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 transition-all">
            <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-2xl max-h-screen overflow-y-auto min-w-52 transition-all">
                {!allStepsCompleted && (
                    <div className="flex justify-end items-center pb-3 cursor-pointer" onClick={close}>
                        <Icons iconName={"closeIcon"} />
                    </div>
                )}

                {!allStepsCompleted ? (
                    <div className="flex flex-col gap-4 justify-center items-center p-4">
                        <div >
                            <SandClock />
                        </div>
                        <h1 className="font-bold text-lg text-center text-met-primary animate-pulse">Processing your request, please wait...</h1>
                        <div className="flex flex-col gap-2">
                            <div>
                                {isAccessRequested ? (
                                    <div className="flex gap-4">
                                        {huiNotifyData ? <SuccesStepIcon/> : <WarningIcon color="text-yellow-500"/> }
                                        <Label fontSize={"lg"}>{`Consent status: ${huiNotifyData ? "Granted" : "Requested"}.`}</Label>
                                    </div>
                                ) : (
                                    <div className="flex gap-4">
                                        <LoadingIcon color="text-yellow-400"/>
                                        <Label fontSize={"lg"}>Requesting consent status from the patient.</Label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col gap-4 p-4 w-full">
                        <div className="flex flex-col gap-2">
                            <div className="flex w-full gap-4">
                                <SuccesStepIcon className="scale-110 transition-all" />
                                <Label fontSize="lg" fontWeight="bold">Consent status: Granted</Label>
                            </div>
                            <div className="flex w-full gap-4">
                                <SuccesStepIcon className="scale-110 transition-all" />
                                <Label fontSize="lg" fontWeight="bold">HUI notify is successfull</Label>
                            </div>
                        </div>
                        <div className="flex justify-end w-full">
                            <Button onClickCb={() => onConfirm()} variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-4">
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ConsentStatusPopup;
