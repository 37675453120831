import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { FORM_FIELDS_FOR_INVOICE } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import Label from "../../../../../components/common-components/label/Label";
import LineItem from "./LineItem";
const fields = [
    { fieldName: FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE, isRequired: true },
]
const validationSchema = getValidationSchema(fields);

const Invoice = ({onConfirm, close}) => {
    const [itemsList, setItemsList] = useState([]);
    const [isItemsPopupOpen, setIsItemsPopupOpen] = useState(false);
    const [itemsError, setItemsError] = useState("");

    useEffect(() => {
        if(itemsList.length > 0) setItemsError("");
    }, [itemsList])

    return(
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE]:"",
                    [FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if(itemsList.length === 0) {
                        setItemsError("Atleast one line item is required");
                        return;
                    }
                    const data = {
                        identifier:{
                            type: {
                                text: values[FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE],
                            }
                        },
                        type:{
                            text: values[FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE],
                        },
                        lineItem: itemsList.map((item) => item.data),
                    }
                
                    onConfirm((prev) => [...prev, { type: `Invoice ${prev.length + 1}`, data }]);
                    close();
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Invoice</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" grid grid-cols-2 gap-2">
                                <Input
                                    label="Identifier Type"
                                    placeholder="Plain text representation of the concept"
                                    name={FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.IDENTIFIER_TYPE, e.target.value)}
                                />
                                <Input
                                    label="Invoice Type"
                                    placeholder="Representation defined by the system for Type of Invoice"
                                    name={FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.INVOICE_TYPE, e.target.value)}
                                />
                                <div className="col-span-2">
                                    <ItemManager
                                        key={`InvoiceLineItems`}
                                        title={<>Line items fo this Invoice <span className="text-red-500">*</span></>} 
                                        items={itemsList}
                                        setItems={setItemsList}
                                        setIsPopupOpen={setIsItemsPopupOpen}
                                        customHeight="52"
                                    />
                                    {itemsError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {itemsError}
                                        </Label>
                                    )}
                                </div>

                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
        {isItemsPopupOpen &&
            <LineItem
                onConfirm={setItemsList}
                close={() => setIsItemsPopupOpen(false)}
            />
        }
    </>
    )
}
export default Invoice;