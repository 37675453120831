import React, { useEffect, useState } from 'react'
import Heading from '../../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../../components/common-components/heading/Constants'
import { ABHA_HEALTH_RECORD_NAVS, ABHA_HEALTH_RECORD_VIEW_NAVS } from '../Constants';
import { useDispatch } from 'react-redux';

import NavItem from '../NavItem';
import ConsentInitRequest from './ConsentInitRequest';
import ConsentStatus from './ConsentStatus';
import { setBundlesData, setConsetRequestData, setDecryptedData, setHUInotifyData } from '../../PatientDashboardSlice';
import ViewRecords from './ViewRecords';

function ViewABHAhealthRecord({handleABHAhealthRecord}) {
    const [navigationItems, setNavigationItems] = useState([ABHA_HEALTH_RECORD_VIEW_NAVS.CONSENT_INIT_REQUEST]);
    const dispatch = useDispatch()

    const handleNavigation = (step) => {
        setNavigationItems(prevState => [...prevState, step])
    }

    useEffect(() => {
        return () => {
            dispatch(setConsetRequestData(null))
            dispatch(setHUInotifyData(null));
            dispatch(setDecryptedData(null));
            dispatch(setBundlesData(null));
        }
    }, [dispatch])

    return (
        <div className='flex flex-col w-full h-full gap-8 p-2'>
            <div className='flex flex-col gap-2'>
                <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>View ABHA Health Record</Heading>
                <div className='flex gap-2 flex-wrap'>
                    {navigationItems.map((item, index) =>(
                        <NavItem key={`${item}${index}`} text={item} index={index} length = {navigationItems.length}/>
                    ))}
                </div>
            </div>
            <div className='z-50 w-full h-full border-2 rounded-md border-gray-200 py-4 px-4 flex flex-col justify-between'>
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_VIEW_NAVS.CONSENT_INIT_REQUEST && 
                    <ConsentInitRequest onConfirm={handleNavigation} handleCancle = {handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_VIEW_NAVS.CONSENT_STATUS && 
                    <ConsentStatus onConfirm={handleNavigation} handleCancle = {handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_VIEW_NAVS.VIEW_RECORDS && 
                    <ViewRecords handleCancle = {handleABHAhealthRecord}/>
                }
            </div>
        </div>
    )
}

export default ViewABHAhealthRecord;