const WarningIcon = ({color = "text-yellow-500"}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`h-6 w-6 ${color}`}
    >
        <path d="M12 9v4" />
        <path d="M12 17h.01" />
        <path d="M10.29 3.86l-7.8 13.49A2 2 0 0 0 4.2 21h15.6a2 2 0 0 0 1.71-3.64l-7.8-13.49a2 2 0 0 0-3.42 0z" />
    </svg>
);

export default WarningIcon;
