import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { APPOINTMENT_STATUS_OPTIONS, FORM_FIELDS_FOR_APPOINTMENT } from "../Constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import ItemManager from "../ItemManager";
import AddSpecificType from "../AddSpecificType";
import Label from "../../../../../components/common-components/label/Label";

const fields = [
    { fieldName: FORM_FIELDS_FOR_APPOINTMENT.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_APPOINTMENT.START, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

const Appointment = ({ open, close, onConfirm }) => {

    const [serviceList, setServiceList] = useState([]);
    const [specialityList, setSpecialityList] = useState([]);
    const [participantList, setParticipantList] = useState([]);

    const [isServicePopupOpen, setIsServicePopupOpen] = useState(false);
    const [isSpecialityPopupOpen, setISSpecialityPopupOpen] = useState(false);
    const [isParticipantPopupOpen, setIsParticipantPopupOpen] = useState(false)
    const [participantError, setParticipantError] = useState("");

    useEffect(() => {
        if(participantList.length > 0) setParticipantError("")
    },[participantList])
    return (
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_APPOINTMENT.STATUS]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.SERVICE_CATEGORY]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.SERVICE_TYPE]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.SPECIALITY]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.REASON_CODE]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.START]: "",
                    [FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_TYPE]: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if(participantList.length <= 0) {
                        setParticipantError("Atleast 1 participant is required")
                        return;
                    }
                    const appointmentData = {
                        resourceType: "Appointment",
                        status: values[FORM_FIELDS_FOR_APPOINTMENT.STATUS].value,
                        serviceCategory: {
                            text: values[FORM_FIELDS_FOR_APPOINTMENT.SERVICE_CATEGORY],
                        },
                        serviceType: serviceList.map((service) => ({ text: service.data })),
                        specialty: specialityList.map((speciality) => ({ text: speciality.data })),
                        appointmentType: {
                            text: values[FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE],
                        },
                        reasonCode: {
                            text: values[FORM_FIELDS_FOR_APPOINTMENT.REASON_CODE],
                        },
                        start: new Date(values[FORM_FIELDS_FOR_APPOINTMENT.START]).toISOString(),
                        participant: participantList.map((participant) => ({type: {text: participant.data}})),
                    };
                    onConfirm((prev) => [...prev, { type: `Appointment ${prev.length + 1}`, data: appointmentData}]);
                    close();
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit, handleBlur }) => (
                    
                    <ModalComponent
                        open={open}
                        title={"Add Appointment"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={close}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                        customBodyClasses="overflow-visible"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <SelectDropdown
                                label="Appointment Status"
                                name={FORM_FIELDS_FOR_APPOINTMENT.STATUS}
                                customClasses="w-full"
                                isRequired={true}
                                placeholder="Select"
                                value={values[FORM_FIELDS_FOR_APPOINTMENT.STATUS]}
                                options={APPOINTMENT_STATUS_OPTIONS}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.STATUS, selectedOption)
                                }
                            />
                            <Input
                                label="Service Category"
                                placeholder="Please enter service category text"
                                name={FORM_FIELDS_FOR_APPOINTMENT.SERVICE_CATEGORY}
                                value={values[FORM_FIELDS_FOR_APPOINTMENT.SERVICE_CATEGORY]}
                                onChangeCb={(e) =>
                                    setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.SERVICE_CATEGORY, e.target.value)
                                }
                                onBlurCb={handleBlur}
                            />
                            <ItemManager
                                title="Service Type"
                                items={serviceList}
                                setItems={setServiceList}
                                setIsPopupOpen={setIsServicePopupOpen}
                                customHeight="52"
                            />
                            <ItemManager
                                title="Specialty"
                                items={specialityList}
                                setItems={setSpecialityList}
                                setIsPopupOpen={setISSpecialityPopupOpen}
                                customHeight="52"
                            />
                            <Input
                                label="Appointment Type"
                                placeholder="The style of appointment or patient that has been booked in the slot (not service type)"
                                name={FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE}
                                value={values[FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE]}
                                onChangeCb={(e) =>
                                    setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.APPOINTMENT_TYPE, e.target.value)
                                }
                                onBlurCb={handleBlur}
                            />
                            <Input
                                label="Reason code/text"
                                placeholder="Please enter reason code/text"
                                name={FORM_FIELDS_FOR_APPOINTMENT.REASON_CODE}
                                value={values[FORM_FIELDS_FOR_APPOINTMENT.REASON_CODE]}
                                onChangeCb={(e) =>
                                    setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.REASON_CODE, e.target.value)
                                }
                                onBlurCb={handleBlur}
                            />
                            <Input
                                label="Start Time"
                                name={FORM_FIELDS_FOR_APPOINTMENT.START}
                                type="datetime-local"
                                isRequired={true}
                                value={values[FORM_FIELDS_FOR_APPOINTMENT.START]}
                                onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_APPOINTMENT.START, e.target.value)}
                                onBlurCb={handleBlur}
                            />
                            <div className="col-span-2">
                                <ItemManager
                                    title={<>Participants <span className="text-red-500">*</span></>} 
                                    items={participantList}
                                    setItems={setParticipantList}
                                    setIsPopupOpen={setIsParticipantPopupOpen}
                                    customHeight="52"
                                />
                                {participantError && (
                                    <Label fontSize="sm" fontWeight="bold" color="red-500">
                                        {participantError}
                                    </Label>
                                )}
                            </div>
                            
                        </div>
                    </ModalComponent>
                )}
            </Formik>
            {isServicePopupOpen && 
                <AddSpecificType
                    open={isServicePopupOpen}
                    close={() => setIsServicePopupOpen(false)}
                    label={"Service Type"}
                    placeholder={"The specific service that is to be performed during this appointment"}
                    setList={setServiceList}
                    name={FORM_FIELDS_FOR_APPOINTMENT.SERVICE_TYPE}
                />
            }
            {isSpecialityPopupOpen && 
                <AddSpecificType
                    open={isSpecialityPopupOpen}
                    close={() => setISSpecialityPopupOpen(false)}
                    label={"Specialty"}
                    placeholder={"The specialty of a practitioner that would be required to perform the service requested in this appointment"}
                    setList={setSpecialityList}
                    name={FORM_FIELDS_FOR_APPOINTMENT.SPECIALITY}
                />
            }
            {isParticipantPopupOpen && 
                <AddSpecificType
                    open={isParticipantPopupOpen}
                    close={() => setIsParticipantPopupOpen(false)}
                    label={"Participant Type"}
                    placeholder={"Enter the Participant name involved in appointment"}
                    setList={setParticipantList}
                    name={FORM_FIELDS_FOR_APPOINTMENT.PARTICIPANT_TYPE} 
                />
            }
        </>
    );
};

export default Appointment;
