const LoadingIcon = ({color = "text-gray-400"}) => (
    <svg 
        className={`h-6 w-6 ${color}`}
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="none"
        style={{ animation: 'spin 1.5s linear infinite' }} 
    >
        <path d="M6 2h12M6 22h12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        <path d="M8 2v3a5 5 0 001.5 3.5L12 12l2.5-3.5A5 5 0 0016 5V2" 
            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        <path d="M16 22v-3a5 5 0 00-1.5-3.5L12 12l-2.5 3.5A5 5 0 008 19v3" 
            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        <path className="opacity-75 animate-pulse" d="M9 6h6v3l-3 3-3-3V6z" fill="currentColor"/>
        <path className="opacity-75 animate-pulse" d="M15 18H9v-3l3-3 3 3v3z" fill="currentColor"/>
    </svg>
);

export default LoadingIcon;
