import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import SelectDropdown from "../../../components/common-components/selectDropdown";
import { componentKey } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { createABHAPatient } from "../../../pages/Patients/AddPatient/AddPatientSaga";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../../components/icons/Icons";
import { getValidationSchema } from "../../../libs/formsUtils";

const fields = [
	{ fieldName: "selectPatient", isRequired: true, isDropdown: true },
];

const validationSchema = getValidationSchema(fields);

const SelectPatientModal = ({ close, onSubmit }) => {
    const { patientsList, createPatientData} = useSelector((state) => state[componentKey]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    
    const dispatch = useDispatch();

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
            <div className="flex justify-between items-center border-b pb-4">
            <h2 className="text-lg font-semibold">Choose Patient to Proceed</h2>
            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                <Icons iconName={"closeIcon"}/>
            </div>
            </div>

            <Formik
            initialValues={{
                selectPatient: { label: "Select Patient", value: "Select Patient" },
            }}
            validationSchema={validationSchema}
            onSubmit={() => {
                if(!selectedPatient){
                    return;
                }
                const payload = {
                    txnId:patientsList.txnId,
                    token:patientsList.token,
                    abhaNumber: selectedPatient.ABHANumber,
                    providerId: createPatientData.providerId,
                    providerLocationId : createPatientData.providerLocationId,
                    registrationDate: createPatientData.registrationDate,
                }
                dispatch(createABHAPatient({createABHAPatientData: payload}));
                close()

            }}
            >
            {({ values, setFieldValue, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="mt-4">
                        <SelectDropdown
                        label="Select Patient"
                        name="selectPatient"
                        customClasses="relative z-50"
                        value={values["selectPatient"]}
                        placeholder="Select"
                        onChangeCb={(value) => {
                            const patient = patientsList?.accounts.find(
                            (p) => p.name === value.label);
                            
                            if (patient) {
                                setSelectedPatient(patient);
                                setFieldValue("selectPatient", value);
                            }else {
                                setSelectedPatient(null)
                            }
                        }}
                        options={[
                                ...(patientsList?.accounts?.map((patient) => ({
                              label: patient.name,
                              value: patient.name,
                            })) || [])
                          ]}
                        />
                        
                    </div>

                    <div className="flex justify-end mt-6">
                        <button
                        type="submit"
                        disabled={!selectedPatient}
                        className={`px-4 py-2 rounded-md ${
                            selectedPatient
                            ? "text-white bg-met-primary"
                            : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        }`}
                        >
                        Confirm
                        </button>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
        </div>
    );
};

export default SelectPatientModal;
