import React from 'react';
import Icons from '../../../../../../components/icons/Icons';
import Heading from '../../../../../../components/common-components/zoom/components/heading/Heading';
import { HEADING } from '../../../../../../components/common-components/zoom/components/heading/constants/constants';
import { DiagnosticReport } from './DiagnosticReport';
import { MedicationRequest } from './MedicationRequest';
import { CarePlan } from './CarePlan';
import { Procedure } from './Procedure';
import FamilyMemberHistory from './FamilyMemberHistory';
import ServiceRequest from './ServiceRequest';
import { Appointment } from './Appointment';
import { DocumentReference } from './DocumentReference';

export const formatResourceType = (type) => {
  return type.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const FieldRow = ({ label, value }) => {
  if (!value) return null;
  return (
    <div className="my-1">
      <strong className="mr-1">{label}:</strong>
      <span>{value}</span>
    </div>
  );
};

const FHIRBundleViewer = ({ title, bundle, close }) => {
  const composition = bundle.entry?.find(
    (entry) => entry?.resource?.resourceType === 'Composition'
  )?.resource;
  const getResourceByReference = (referenceId) =>
    bundle.entry?.find((entry) => entry.fullUrl === referenceId)?.resource || null;

  const practitionerEntries = bundle.entry?.filter(
    (entry) => entry?.resource?.resourceType === 'Practitioner'
  ) || [];

  const practitionersMap = new Map();
  practitionerEntries.forEach((entry) => {
    const resource = entry.resource;
    let name =
      resource.name?.[0]?.text ||
      `${resource.name?.[0]?.given?.join(' ') || ''} ${resource.name?.[0]?.family || ''}`.trim();

    if (name && !practitionersMap.has(name)) {
      practitionersMap.set(name, resource);
    }
  });
  const practitioners = Array.from(practitionersMap.values());

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-900 bg-opacity-75 transition-opacity duration-300">
        <div className="bg-white border border-gray-200 rounded w-[95%] md:w-[65%] h-[95%] overflow-hidden">
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
            <Heading type={HEADING.H1}className="text-2xl font-semibold">
                {formatResourceType(title)} Record
            </Heading>
            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                <Icons iconName={"closeIcon"}/>
            </div>
            </div>

            <div className="flex flex-col h-[95%] overflow-y-auto">
            <div className="border-r border-gray-200 p-4 flex flex-col gap-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {bundle.entry ? (
                    bundle?.entry?.map((entry) => {
                        if(!entry.resource) return null;
                        const resource = entry.resource;
                        const resourceType = resource.resourceType;
                        if (resourceType === 'Patient') {
                            return <Patient resource={resource} key={`${resourceType}${resource.id}`} />;
                        }
                        if (resourceType === 'Organization') {
                            return <Organization resource={resource} key={`${resourceType}${resource.id}`} />;
                        }
                        if (resourceType === 'Encounter') {
                            return <Encounter resource={resource} key={resource.id} />;
                        }
                        return null;
                        })
                ) : null}

                {practitioners && practitioners?.length > 0 && (
                    <div className="p-4 border border-gray-200 rounded">
                    <Heading type={HEADING.H2} className="text-xl font-medium mb-2">Practitioners</Heading>
                    {practitioners.map((practitioner, index) => {
                        const name =
                        practitioner.name?.[0]?.text ||
                        `${practitioner.name?.[0]?.given?.join(' ') || ''} ${practitioner.name?.[0]?.family || ''}`.trim() ||
                        'N/A';

                        return (
                        <div
                            key={index}
                            className="mb-1 p-1 px-2 border border-gray-200 rounded"
                        >
                            <FieldRow label="Full Name" value={name} />
                            {practitioner.telecom?.map((telecom, idx) => (
                            <FieldRow
                                key={idx}
                                label={`Contact ${idx + 1} (${telecom.system || 'Other'})`}
                                value={telecom.value}
                            />
                            ))}
                        </div>
                        );
                    })}
                    </div>
                )}
                </div>

                {composition && composition.section ? (
                    <div className='mb-6 p-4 border border-gray-200 rounded'>
                        <div className='border-b-2 border-gray-200 mb-4'>
                            {composition?.title && <Heading type={HEADING.H1}>{composition?.title}</Heading>}
                            <FieldRow label="Status" value={composition?.status} />
                            <FieldRow label="Date" value={formatDate(composition?.date)} />
                        </div>
                        
                        {(Array.isArray(composition.section) ? composition.section : [composition.section]).map((section, sIndex) =>
                            section.entry?.map((entryRef, index) => {
                                const resource = getResourceByReference(entryRef.reference);
                                if (!resource) return null;
                                const ResourceComponent = resourceComponents[resource.resourceType] || null;
                                return ResourceComponent ? (
                                    <div key={`${resource.id}-${sIndex}-${index}`}>
                                        <ResourceComponent resource={resource} bundle={bundle} />
                                    </div>
                                ) : null;
                            })
                        )}
                    </div>
                    
                ) : null}
            </div>
            </div>
        </div>
        </div>
  );
};

export const renderObservationResult = (obs) => {
  if (!obs)
    return null;

  const { status, code, valueQuantity, valueString, valueCodeableConcept, interpretation } = obs;
  return (
    <div>
      <FieldRow label="Status" value={status} />
      <FieldRow label="Observation Type" value={code?.text} />
      {valueQuantity && (
        <FieldRow
          label="Value"
          value={`${valueQuantity.value} ${valueQuantity.unit}`}
        />
      )}
      {valueString && <FieldRow label="Value" value={valueString} />}
      {valueCodeableConcept && <FieldRow label="Value" value={valueCodeableConcept.text} />}
      {interpretation && <FieldRow label="Interpretation" value={interpretation?.text} />}
    </div>
  );
};

const Practitioner = ({ resource }) => (
  <div className="mb-6 p-4 border border-gray-200 rounded">
    <Heading type={HEADING.H3} className="text-lg font-semibold mb-2">
      {resource.resourceType}
    </Heading>
    <FieldRow label="Name" value={resource.name?.[0]?.text} />
    {resource.telecom?.map((telecom, index) => (
      <FieldRow key={index} label={`Contact ${index + 1}`} value={telecom.value} />
    ))}
  </div>
);

const Patient = ({ resource }) => (
  <div className="p-4 border border-gray-200 rounded">
    <Heading type={HEADING.H3} className="text-lg font-semibold mb-2">
      {resource.resourceType}
    </Heading>
    <FieldRow label="Name" value={resource.name?.[0]?.text} />
    <FieldRow label="Gender" value={resource.gender} />
    <FieldRow label="Birthdate" value={formatDate(resource.birthDate)} />
  </div>
);

const Organization = ({ resource }) => (
  <div className="p-4 border border-gray-200 rounded">
    <Heading type={HEADING.H3} className="text-lg font-semibold mb-2">
      {resource?.resourceType}
    </Heading>
    <FieldRow label="Name" value={resource.name} />
    {resource.telecom?.map((telecom, index) => (
      <FieldRow key={index} label={`Contact ${index + 1}`} value={telecom.value} />
    ))}
  </div>
);

const Encounter = ({ resource }) => {
    const start = formatDate(resource.period?.start);
    const end = formatDate(resource.period?.end);
    
    const period = start && end ? `${start} to ${end}` : null;
    return (
        <div className="p-4 border border-gray-200 rounded">
            <Heading type={HEADING.H3} className="text-lg font-semibold mb-2">
                {resource.resourceType}
            </Heading>
            <FieldRow label="Status" value={resource.status} />
            <FieldRow label="Classification" value={resource.class?.display} />
            <FieldRow
                label="Period"
                value={period}
            />
            <FieldRow label="Service type" value={resource.serviceType?.text || resource.serviceType?.coding[0]?.display} />
        </div>
    )
};

const Observation = ({resource}) => {
    const { status, code, valueQuantity, valueString, valueCodeableConcept, interpretation, hasMember } = resource;
    return(
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md border border-gray-300">
            <Heading type={HEADING.H3} className="text-xl font-bold text-gray-800 mb-4">
                {formatResourceType(resource.resourceType)}
            </Heading>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-2">
                <FieldRow label="Status" value={status} />
                <FieldRow label="Observation Type" value={code?.text} />
                {valueQuantity && (
                    <FieldRow
                    label="Value"
                    value={`${valueQuantity.value} ${valueQuantity.unit}`}
                    />
                )}
                {valueString && <FieldRow label="Value" value={valueString} />}
                {valueCodeableConcept && <FieldRow label="Value" value={valueCodeableConcept.text} />}
                {interpretation && <FieldRow label="Interpretation" value={interpretation?.text} />}
            </div>
            {hasMember?.length > 0 &&
                <div className="mt-4 pl-4 border-l border-gray-300">
                <Heading type={HEADING.H4}className="text-md font-medium text-gray-600 mb-2">Other observations</Heading>
                <div className="flex gap-2">
                {hasMember.map((res, index) => (
                    <div key={index} className="mb-2 p-2 border border-gray-200 rounded">
                        {renderObservationResult(res)}
                    </div>
                ))}
                </div>
            </div>
            }
        </div>
    )
}

const Condition = ({resource}) => {
    const {code} = resource;
    return (
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md border border-gray-300">
            <Heading type={HEADING.H3} className="text-xl font-bold text-gray-800 mb-4">
                {formatResourceType(resource?.resourceType)}
            </Heading>
            <FieldRow label={"Condition"} value={code?.text}/>
        </div>
    )
}

const resourceComponents = {
  DiagnosticReport,
  DocumentReference,
  MedicationRequest,
  Organization,
  Encounter,
  Patient,
  Practitioner,
  CarePlan,
  Procedure,
  FamilyMemberHistory,
  Observation,
  Condition,
  ServiceRequest,
  Appointment
};

export default FHIRBundleViewer;
