import React from "react";
import { FieldRow, formatResourceType } from "./FHIRBundleViewer";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";

export default function ServiceRequest  ({resource}) {
    const {code, intent, status, text} = resource;
    return (
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md border border-gray-300">
            <Heading type={HEADING.H3}className="text-xl font-bold text-gray-800 mb-4">
                {formatResourceType(resource?.resourceType)}
            </Heading>
            <FieldRow label={"Status"} value={status}/>
            <FieldRow label={"Intent"} value={intent}/>
            <FieldRow label={"Code/Text"} value={code.text}/>
            <FieldRow label={"Summary"} value={text}/>
        </div>
    )
}