import React from "react";
import { Formik } from "formik";
import { FORM_FIELDS_FOR_INVOICE } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import HealthRecordHelper from "../utils/utils";
const fields = [
    { fieldName: FORM_FIELDS_FOR_INVOICE.UNITS, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.QUANTITY, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_INVOICE.CHARGE_CODE, isRequired: true },
]
const validationSchema = getValidationSchema(fields);

const LineChargeItem = ({onConfirm, close}) => {

    return(
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_INVOICE.CHARGE_CODE]:"",
                    [FORM_FIELDS_FOR_INVOICE.OCCURRENCE]:"",
                    [FORM_FIELDS_FOR_INVOICE.QUANTITY]:"",
                    [FORM_FIELDS_FOR_INVOICE.UNITS]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const data = HealthRecordHelper.removeEmptyFields({
                        code: {
                            text: values[FORM_FIELDS_FOR_INVOICE.CHARGE_CODE],
                        },
                        occurrence: values[FORM_FIELDS_FOR_INVOICE.OCCURRENCE],
                        quantity: {
                            value: values[FORM_FIELDS_FOR_INVOICE.QUANTITY],
                            unit: values[FORM_FIELDS_FOR_INVOICE.UNITS]
                        }
                    })
                    onConfirm((prev) => [...prev, { type: `Charge Item ${prev.length + 1}`, data }]);
                    close();
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Add Charge Item</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" grid grid-cols-2 gap-2">

                                <Input
                                    label="Charge Item Code/Text"
                                    placeholder="A code that identifies the charge, like a billing code"
                                    name={FORM_FIELDS_FOR_INVOICE.CHARGE_CODE}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.CHARGE_CODE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.CHARGE_CODE, e.target.value)}
                                />
                                <Input
                                    label="Occurrence"
                                    name={FORM_FIELDS_FOR_INVOICE.OCCURRENCE}
                                    type="datetime-local"
                                    value={values[FORM_FIELDS_FOR_INVOICE.OCCURRENCE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.OCCURRENCE, e.target.value)}
                                />
                                 <Input
                                    label="Quantity"
                                    placeholder="Quantity of which the charge item has been serviced. Indicates the total quantity of medicines "
                                    name={FORM_FIELDS_FOR_INVOICE.QUANTITY}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.QUANTITY]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.QUANTITY, e.target.value)}
                                />
                                <Input
                                    label="Units"
                                    placeholder="Units for Quantity"
                                    name={FORM_FIELDS_FOR_INVOICE.UNITS}
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_INVOICE.UNITS]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_INVOICE.UNITS, e.target.value)}
                                />
                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    </>
    )
}
export default LineChargeItem;