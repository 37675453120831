import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_NAVS, RECORD_STATUS, IMMUNIZATIO_RECORD } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Label from "../../../../../components/common-components/label/Label";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import DocumentReference from "./DocumentReference";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import { createImmunizationBundle } from "../../PatientDashboardSaga";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import Immunization from "./Immunization";
import ImmunizationRecommendation from "./ImmunizationRecommendation";
import HealthRecordHelper from "../utils/utils";

const fields = [
	{ fieldName: IMMUNIZATIO_RECORD.STATUS, isRequired: true, isDropdown: true },
];

const validationSchema = getValidationSchema(fields);

const ImmunizationRecord = ({ onConfirm, handleCancle }) => {
    const { patientData, selectedBundle } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [documentReferenceList, setDocumentReferenceList] = useState([]);
    const [immunizationList, setImmunizationList] = useState([])
    const [immunizationRecommendationList, setImmunizationRecommendationList] = useState([])

    const [isDocumentReferencePopupOpen, setIsDocumentReferencePopupOpen] = useState(false);
    const [isImmunizationPopupOpen, setIsImmunizationPopupOpen] = useState(false);
    const [isImmunizationRecommendationOpen, setIsImmunizationRecommendationOpen] = useState(false);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

    const [sectionError, setSectionError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(selectedBundle) {
            setOpenSuccessPopup(true);
        }
    },[selectedBundle])

    useEffect(() => {
        if(documentReferenceList.length > 0 || immunizationList.length > 0 || immunizationRecommendationList.length > 0){
            setSectionError("");
        }
    },[documentReferenceList, immunizationList, immunizationRecommendationList]) 

    const structurePayloadWithAllData = (values) => {
        const payload = {
            status: values[IMMUNIZATIO_RECORD.STATUS].value,
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            section: [
                immunizationList.length > 0 && {
                    immunizationEntry: immunizationList.map((item) => item.data),
                },
                immunizationRecommendationList.length > 0 && {
                    immunizationRecommendation: {
                        resourceType:"ImmunizationRecommendation",
                        recommendation: immunizationRecommendationList.map((item) =>  item.data)
                    }
                },
                documentReferenceList.length > 0 && {
                    documentReferenceEntry: documentReferenceList.map((item) => item.data),
                },
            ].filter(Boolean),
        };
        return payload;
    };

    return (
        <>
        <Formik
            initialValues={{
                [IMMUNIZATIO_RECORD.STATUS]: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const payload = structurePayloadWithAllData(values);
                if(payload.section.length <= 0) {
                    setSectionError("Atleast 1 section is required")
                    return;
                }
                dispatch(createImmunizationBundle(payload));
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <>
                    <form
                        onSubmit={handleSubmit}
                        className="w-full flex flex-col gap-4 justify-between"
                    >
                        <div className="h-full flex flex-col gap-2 ">
                            <SelectDropdown
                                label="Composition Status"
                                name={IMMUNIZATIO_RECORD.STATUS}
                                isRequired={true}
                                customClasses="w-1/2"
                                placeholder="Select"
                                value={values[IMMUNIZATIO_RECORD.STATUS]}
                                options={RECORD_STATUS}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(IMMUNIZATIO_RECORD.STATUS, selectedOption)
                                }
                            />
                            <div>
                                <div className="px-4 py-2 border-2 border-gray-200 rounded-lg flex flex-col gap-2">
                                    <Heading type={HEADING.H2}> Section <span className="text-red-500">*</span></Heading>
                                    <div className="max-h-[35vh] overflow-y-auto">
                                        <div className="flex flex-col gap-4">
                                            <ItemManager
                                                key={`Immunization`}
                                                title={ "Immunization entry"}
                                                items={immunizationList}
                                                setItems={setImmunizationList}
                                                setIsPopupOpen={setIsImmunizationPopupOpen}
                                                customHeight="52"
                                            />
                                            <ItemManager
                                                key={`ImmunizationRecommendation`}
                                                title={ "Immunization Recommendation entry"}
                                                items={immunizationRecommendationList}
                                                setItems={setImmunizationRecommendationList}
                                                setIsPopupOpen={setIsImmunizationRecommendationOpen}
                                                customHeight="52"
                                            />
                                            <ItemManager
                                                key={`DocumentReference	${uuidv4()}`}
                                                title={ "Document Reference	 entry"}
                                                items={documentReferenceList}
                                                setItems={setDocumentReferenceList}
                                                setIsPopupOpen={setIsDocumentReferencePopupOpen}
                                                customHeight="52"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {sectionError && (
                                    <Label fontSize="sm" fontWeight="bold" color="red-500">
                                        {sectionError}
                                    </Label>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                Submit
                            </Button>
                        </div>
                    </form>
                </>
            
            )}
        </Formik>
        {isImmunizationPopupOpen && 
            <Immunization 
                onConfirm = {setImmunizationList} 
                close = {() => setIsImmunizationPopupOpen(false)}
            />
        }
        {isImmunizationRecommendationOpen && 
            <ImmunizationRecommendation 
                open={isImmunizationRecommendationOpen}
                onConfirm = {setImmunizationRecommendationList} 
                close = {() => setIsImmunizationRecommendationOpen(false)}
            />
        }
        {isDocumentReferencePopupOpen &&
            <DocumentReference
                open={isDocumentReferencePopupOpen}
                onConfirm={setDocumentReferenceList}
                close={() => setIsDocumentReferencePopupOpen(false)}
            />
        }
        <Modal
            title=""
            isOpen={openSuccessPopup}
            onConfirm={() => {
                setOpenSuccessPopup(false);
                onConfirm(ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM);
            }}
            submitText="Okay"
        >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                <SuccessSvg />
                <h1 className="font-bold text-lg">Discharge summary bundle created successfully</h1>
            </div>
        </Modal>
        </>
    );
};

export default ImmunizationRecord;
