import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { FORM_FIELDS_FOR_INVOICE } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import Icons from "../../../../../components/icons/Icons";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import Label from "../../../../../components/common-components/label/Label";
import LineChargeItem from "./LineChargeItem";
import LinePriceComponent from "./LinePriceComponent";

const LineItem = ({onConfirm, close}) => {
    const [chargeItemsList, setChargeItemsList] = useState([]);
    const [priceList, setPriceList] = useState([]);

    const [isChargeItemsPopupOpen, setIsChargeItemsPopupOpen] = useState(false);
    const [isPricePopupOpen, setIsPricePopupOpen] = useState(false);

    const [chargeItemsError, setChargeItemsError] = useState("");
    const [priceError, setPriceError] = useState("");

    useEffect(() => {
        if(chargeItemsList.length > 0) setChargeItemsError("");
        if(priceList.length > 0) setPriceError("");
    }, [chargeItemsList, priceList])

    return(
        <>
            <Formik
                initialValues={{

                }}
                onSubmit={(values) => {
                    if(chargeItemsList.length === 0 || priceList.length === 0){
                        if(!chargeItemsList.length) setChargeItemsError("Charge item is required");
                        if(!priceList.length) setPriceError("Atleast one price component is required");
                        return;
                    }
                    const data = {
                        chargeItemReference: chargeItemsList[0]?.data,
                        priceComponent: priceList.map((item) => item.data),
                    }
                    onConfirm((prev) => [...prev, { type: `Line item ${prev.length + 1}`, data }]);
                    close();
                }}
            >
              {({ handleSubmit }) => (
                <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                    <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center border-b pb-3">
                            <Heading type={HEADING.H2} className="text-lg font-semibold">Line Item of Invoice</Heading>
                            <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                <Icons iconName={"closeIcon"}/>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <div className=" flex flex-col gap-4">
                                <div>
                                    <ItemManager
                                        key={`InvoiceChargeItem`}
                                        title={<>Charge Item <span className="text-red-500">*</span></>} 
                                        items={chargeItemsList}
                                        setItems={setChargeItemsList}
                                        setIsPopupOpen={(value) => {
                                            if(chargeItemsList.length < 1) setIsChargeItemsPopupOpen(value)
                                            else setChargeItemsError("Only one charge item is allowed")
                                        }}
                                        customHeight="52"
                                    />
                                    {chargeItemsError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {chargeItemsError}
                                        </Label>
                                    )}
                                </div>
                                <div>
                                    <ItemManager
                                        key={`InvoicePrice`}
                                        title={<>Price Components <span className="text-red-500">*</span></>} 
                                        items={priceList}
                                        setItems={setPriceList}
                                        setIsPopupOpen={setIsPricePopupOpen}
                                        customHeight="52"
                                    />
                                    {priceError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {priceError}
                                        </Label>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
        {isChargeItemsPopupOpen &&
            <LineChargeItem
                onConfirm={setChargeItemsList}
                close={() => setIsChargeItemsPopupOpen(false)}
            />
        }
        {isPricePopupOpen &&
            <LinePriceComponent
                onConfirm={setPriceList}
                close={() => setIsPricePopupOpen(false)}
            />
        }
    </>
    )
}
export default LineItem;