import React, { useState } from "react";
import Button from "../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";
import Label from "../../../../components/common-components/label/Label";
import { useSelector } from "react-redux";
import { componentKey as PatientDashboardComponentKey } from "../PatientDashboardSlice";
import AddABHAhealthRecord from "./AddRecords/AddABHAhealthRecord";
import ViewABHAhealthRecord from "./ViewRecords/ViewABHAhealthRecord";

const ABHAHealthRecord = () => {
  const { careContextLinkingData } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [openABHAhealthRecord, setOpenABHAhealthRecord] = useState(true);
  const [openAddABHAhealthRecord, setOpenAddABHAhealthRecord] = useState(false);
  const [openViewABHAhealthRecord, setOpenViewABHAhealthRecord] = useState(false);

  const handleAddABHAhealthRecord = () => {
    setOpenABHAhealthRecord(false);
    setOpenAddABHAhealthRecord(true);
  }

  const handleViewABHAhealthRecord = () => {
    setOpenABHAhealthRecord(false);
    setOpenViewABHAhealthRecord(true)
  }

  const handleABHAhealthRecord = () => {
    setOpenAddABHAhealthRecord(false);
    setOpenViewABHAhealthRecord(false)
    setOpenABHAhealthRecord(true);
  }

  return (
    <>
      {openABHAhealthRecord && 
        <div className="flex flex-col w-full h-full gap-8 p-6">
          <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-4">
            ABHA Health Record
          </h2>
          <div className="flex flex-col gap-8 p-8 bg-white rounded-2xl shadow-2xl max-w-3xl mx-auto">
            <p className="text-lg text-gray-700 text-center leading-relaxed">
              Effortlessly manage your ABHA health records. Add new records or view
              existing ones with just a click.
            </p>
            <div className="flex flex-col gap-10 mt-6">
              <div className="flex flex-wrap justify-evenly gap-6">
                <div className="flex flex-col items-center gap-4 w-64 p-6 bg-blue-50 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <Button
                    onClickCb={handleAddABHAhealthRecord}
                    variant={BUTTON_VARIANTS.CONTAINED}
                    customBtnClass="px-8 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-transform duration-200 transform hover:scale-105"
                    aria-label="Add ABHA Health Record"
                  >
                    Add Record
                  </Button>
                  <Label fontSize="sm" fontWeight="medium" color="gray-600" className="text-center">
                    Add a new ABHA health record to your account.
                  </Label>
                </div>
                <div className="flex flex-col items-center gap-4 w-64 p-6 bg-green-50 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
                  <Button
                    onClickCb={handleViewABHAhealthRecord}
                    variant={BUTTON_VARIANTS.TEXT}
                    customBtnClass="px-8 py-3 bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg transition-transform duration-200 transform hover:scale-105"
                    aria-label="View ABHA Health Record"
                  >
                    View Records
                  </Button>
                  <Label fontSize="sm" fontWeight="medium" color="gray-600" className="text-center">
                    View your existing ABHA health records in detail.
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {openAddABHAhealthRecord && <AddABHAhealthRecord handleABHAhealthRecord={handleABHAhealthRecord}/>}
      {openViewABHAhealthRecord && <ViewABHAhealthRecord handleABHAhealthRecord= {handleABHAhealthRecord}/>}
    </>
  );
};

export default ABHAHealthRecord;
