import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import WrapperCard from '../../../components/common-components/Cards/WrapperCard/WrapperCard';
import Sidebar from '../../../containers/Sidebar';
import PatientInfo from './PatientInfo/PatientInfo';
import Allergies from './Allergies/Allergies';
import Diagnoses from './Diagnoses/Diagnoses';
import Medications from './Medications/Medications';
import Vaccine from './Vaccines/Vaccine';
import Vitals from './Vitals/Vitals';
import Appointments from './Appointments/Appointments';
import CardDetails from './CardDetails/CardDetails';
import useGetCurrenPathHook from '../../../libs/customHooks/useGetCurrenPathHook';
import Profile from './Profile/Profile';
import PortalAccess from './PortalAccess/PortalAccess';
import Insurance from './Insurance/Insurance';
import General from '../../../libs/utility/General';
import PatientDashboardInfo from './Dashboard';
import PatientLabDetails from './Lab';
import PatientImaging from './Imaging';
import PatientHistory from './History';
import PatientDocuments from './Documents';
import Billing from './Billing/Billing';
import useUrlPathExists from '../../../libs/customHooks/useUrlPathExists';
import Icons from '../../../components/icons/Icons';
import useGetCurrentScreenSize from '../../../libs/customHooks/useGetCurrentScreenSize';
import { SCREEN_SIZES } from '../../../libs/constant';
import Family from './Family/Family';
import { componentKey as PatientDashboardComponentKey, setPatientId } from '../../Patients/PatientDashboard/PatientDashboardSlice';
import { getAllPatientAllergiesDetails } from './Allergies/PatientAllergiesSaga';
import { getAllPatientDiagnosesDetails } from './Diagnoses/PatientDiagnosesSaga';
import { getAllPatientMedicationsDetails } from './Medications/PatientMedicationsSaga';
import { getAllPatientHistoryDetails } from './History/PatientHistorySaga';
import { getPatientDetails } from './PatientDashboardSaga';
import { getAllPatientNotesDetails } from './Documents/PatientNotesSaga';
import { componentKey as PatientAllergiesComponentKey } from "./Allergies/PatientAllergiesSlice"
import { componentKey as PatientDiagnosesComponentKey } from "./Diagnoses/PatientDiagnosesSlice"
import { componentKey as PatientVaccinesComponentKey } from "./Vaccines/PatientVaccinesSlice"
import { componentKey as PatientVitalsComponentKey } from "./Vitals/PatientVitalsSlice"
import { componentKey as PatientLabComponentKey } from "./Lab/PatientLabSlice"
import { componentKey as PatientImagingComponentKey } from "./Imaging/PatientImagingSlice"
import { componentKey as PatientMedicationsComponentKey } from "./Medications/PatientMedicationsSlice"
import { componentKey as PatientHistoryComponentKey } from "./History/PatientHistorySlice"
import { componentKey as PatientDocumentsComponentKey } from "./Documents/PatientDocumentsSlice"
import Label from '../../../components/common-components/label/Label';
import { getAllPatientVaccinesDetails } from './Vaccines/PatientVaccinesSaga';
import { getAllPatientVitalsDetails } from './Vitals/PatientVitalsSaga';
import { getAllPatientLabResultDetails } from './Lab/PatientLabSaga';
import { getAllPatientImagingResultDetails } from './Imaging/PatientImagingSaga';
import { getAllPatientDocumentsDetails } from './Documents/PatientDocumentsSaga';
import VitalsTest from './VitalsTest/VitalsTest';
import PatientVitals from './Vitals';
import { USER_PERMISSIONS } from '../../../libs/UserPermissionsConstants';
import ShowForAllowedPermissionsOnly from '../../../containers/Wrappers/ShowForAllowedPermissionsOnly';
import ABHAHealthRecord from './ABHAHealthRecord/AbhaHealthRecord';

const PatientDashboard = () => {
	const { patientId } = useParams();
	const navigate = useNavigate();
	const currrentPath = useGetCurrenPathHook();
	const isPathExist = useUrlPathExists();
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);
	const dispatch = useDispatch();

	const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
	const { allergiesData } = useSelector((state) => state[PatientAllergiesComponentKey]);
	const { allDiagnosesData } = useSelector((state) => state[PatientDiagnosesComponentKey]);
	const { allVaccinesData } = useSelector((state) => state[PatientVaccinesComponentKey]);
	const { allVitalsData } = useSelector((state) => state[PatientVitalsComponentKey]);
	const { allLabResultData } = useSelector((state) => state[PatientLabComponentKey]);
	const { allImagingResultData } = useSelector((state) => state[PatientImagingComponentKey]);
	const { allCurrentMedicationsData, allPastMedicationsData } = useSelector((state) => state[PatientMedicationsComponentKey]);
	const { allMedicalHistoryData, allSurgicalHistoryData } = useSelector((state) => state[PatientHistoryComponentKey]);
	const { allDocumentsData } = useSelector((state) => state[PatientDocumentsComponentKey]);

	const SIDEBAR_MENU_OPTIONS = useMemo(() => {
		return [
			{
				label: 'Dashboard',
				prefixIcon: 'patientDashboardIcon',
				suffixIcon: '',
				path: '/dashboard/*',
				component: <PatientDashboardInfo />,
			},
			{
				label: 'ABHA Health Record',
				prefixIcon: 'infoIcon',
				suffixIcon: '',
				path: '/abha-health-record',
				component: <ABHAHealthRecord/>,
			},
			{
				label: 'Allergies', prefixIcon: 'allergiesIcon', suffixIcon: allergiesData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allergiesData.length}
				</Label> : '', path: '/allergies', component: <Allergies />
			},
			{
				label: 'Diagnoses', prefixIcon: 'diagnosesIcon', suffixIcon: allDiagnosesData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allDiagnosesData.length}
				</Label> : '', path: '/diagnoses', component: <Diagnoses />
			},
			{
				label: 'Medications',
				prefixIcon: 'medicationsIcon',
				suffixIcon: allCurrentMedicationsData?.length || allPastMedicationsData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{Number(allCurrentMedicationsData.length) + Number(allPastMedicationsData.length)}
				</Label> : '',
				path: '/medications',
				component: <Medications />,
			},
			{
				label: 'Vaccines', prefixIcon: 'vaccinesIcon', suffixIcon: allVaccinesData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allVaccinesData.length}
				</Label> : '', path: '/vaccines', component: <Vaccine />
			},
			{
				label: 'Vitals', prefixIcon: 'vitalsIcon', suffixIcon: allVitalsData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allVitalsData.length}
				</Label> : '', path: '/vitals/*', component: <PatientVitals />
			},
			{
				label: 'Lab', prefixIcon: 'labIcon', suffixIcon: allLabResultData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allLabResultData.length}
				</Label> : '', path: '/lab/*', component: <PatientLabDetails />
			},
			{
				label: 'Imaging', prefixIcon: 'imagingIcon', suffixIcon: allImagingResultData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allImagingResultData.length}
				</Label> : '', path: '/imaging/*', component: <PatientImaging />
			},
			{
				label: 'History', prefixIcon: 'historyIcon', suffixIcon: allMedicalHistoryData?.length || allSurgicalHistoryData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{Number(allMedicalHistoryData.length) + Number(allSurgicalHistoryData.length)}
				</Label> : '', path: '/history/*', component: <PatientHistory />
			},
			{
				label: 'Documents',
				prefixIcon: 'documentsIcon',
				suffixIcon: allDocumentsData?.length ? <Label fontWeight='bold' fontSize='10px' color="green-600">
					{allDocumentsData.length}
				</Label> : '',
				path: '/documents/*',
				component:
					<PatientDocuments />
			},
			{
				label: 'Appointments',
				prefixIcon: 'appointmentsIcon',
				suffixIcon: '',
				path: '/appointments',
				component: <Appointments />,
			},
			{
				label: 'Billing',
				prefixIcon: 'billingIcon',
				suffixIcon: '',
				path: '/billing',
				component: <Billing />,
			},
			{
				label: 'Insurance',
				prefixIcon: 'insuranceIcon',
				suffixIcon: '',
				path: '/insurance',
				component: <Insurance patientId={patientId} />,
			},
			// {
			// 	label: 'Card Details',
			// 	prefixIcon: 'cardDetailsIcon',
			// 	suffixIcon: '',
			// 	path: '/card-details',
			// 	component: <CardDetails />,
			// },
			{ label: 'Profile', prefixIcon: 'profileIcon', suffixIcon: '', path: '/profile', component: <Profile /> },
			{
				label: 'Portal Access',
				prefixIcon: 'portalAccessIcon',
				suffixIcon: '',
				path: '/portal-access',
				component: <PortalAccess />,
			},
			{
				label: 'Family',
				prefixIcon: 'familyIcon',
				suffixIcon: '',
				path: '/family',
				component: <Family />,
			},
			{
				label: 'Vitals Test',
				prefixIcon: 'familyIcon',
				suffixIcon: '',
				path: '/vitals-test',
				component: <VitalsTest />,
			},
			{ path: '/', component: <Navigate to={'dashboard'} /> },
		]
	}, [patientId, allergiesData, allDiagnosesData, allCurrentMedicationsData, allPastMedicationsData, allVaccinesData, allVitalsData, allLabResultData, allImagingResultData, allMedicalHistoryData, allSurgicalHistoryData, allDocumentsData])

	const screenSize = useGetCurrentScreenSize();

	useEffect(() => {
		if (screenSize === SCREEN_SIZES.SM) {
			setIsOpenDrawer(false);
		}
	}, [screenSize]);

	useEffect(() => {
		dispatch(setPatientId(patientId));
	}, [patientId, dispatch]);

	useEffect(() => {
		if (currentPatientId) {
			dispatch(getAllPatientAllergiesDetails({ patientId: currentPatientId }));
			dispatch(getAllPatientDiagnosesDetails({ patientId: currentPatientId }));
			dispatch(getAllPatientMedicationsDetails({ patientId: currentPatientId }));
			dispatch(getAllPatientHistoryDetails({ patientId: currentPatientId, historyType: 'pmh' }));
			dispatch(getAllPatientHistoryDetails({ patientId: currentPatientId, historyType: 'psh' }));
			dispatch(getPatientDetails({ patientId: currentPatientId }))
			dispatch(getAllPatientNotesDetails({ patientId: currentPatientId }))
			dispatch(getAllPatientVaccinesDetails({ patientId: currentPatientId }))
			dispatch(getAllPatientVitalsDetails({ patientId: currentPatientId }))
			dispatch(getAllPatientLabResultDetails({ patientId: currentPatientId }))
			dispatch(getAllPatientImagingResultDetails({ patientId: currentPatientId }))
			dispatch(getAllPatientDocumentsDetails({ patientId: currentPatientId }))
		}
	}, [currentPatientId])

	return (
		<div className="flex w-full h-full p-3 column-gap-20px">
			<WrapperCard
				customClasses={`flex flex-col p-2 inset-y-0 left-0 transition-transform transform ${screenSize === SCREEN_SIZES.SM || screenSize === SCREEN_SIZES.XS
					? isOpenDrawer
						? 'translate-x-0'
						: 'translate-x-[-200px] hidden'
					: ''
					}`}
			>
				<Sidebar
					checkIsActivePath={(option) => {
						return isPathExist(General.convertStringToURLPath(option.label).slice(1)) ? true : false;
					}}
					options={SIDEBAR_MENU_OPTIONS}
					onClickCb={(option) => {
						navigate(General.convertStringToURLPath(option.label).slice(1));
						setIsOpenDrawer(false);
					}}
				/>
			</WrapperCard>
			<div className="w-full flex flex-col row-gap-10 relative">
				{!isOpenDrawer && screenSize === SCREEN_SIZES.SM && (
					<div
						className="absolute top-0 left-0  flex items-center justify-center h-[40px] w-[40px] bg-gray-100 shadow-sm cursor-pointer"
						onClick={() => {
							setIsOpenDrawer(!isOpenDrawer);
						}}
					>
						<Icons iconName={'threeDotMenu'} />
					</div>
				)}
				{currrentPath !== "/profile" && (
					<WrapperCard>
						<PatientInfo />
					</WrapperCard>
				)}
				<WrapperCard customClasses="flex-1 p-2">
					<Routes>
						{SIDEBAR_MENU_OPTIONS.map((route, index) => {
							return (
								<Route key={'sidebar-men-item-' + index} path={route.path} element={route.component} />
							);
						})}
					</Routes>
				</WrapperCard>
			</div>
		</div>
	);
};

export default PatientDashboard;
