import React, { useEffect, useState } from "react";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Label from "../../../../../components/common-components/label/Label";
import { useDispatch, useSelector } from "react-redux";
import { dataPushToABDM } from "../../PatientDashboardSaga";
import { componentKey as PatientDashboardComponentKey, } from "../../PatientDashboardSlice";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import { ABHA_HEALTH_RECORD_NAVS } from "../Constants";

const DataPushToABDM = ({onConfirm, handleCancle}) => {
    const { hipNotifyData, selectedBundle, isDataPushedToABDM} = useSelector((state) => state[PatientDashboardComponentKey]);
    const [openDataPushSuccessPopup, setOpenDataPushSuccessPopup] = useState(false);
    const dispatch = useDispatch();

    const handleGenerate = async () => {
        const payload = 
            {
                url: hipNotifyData.url,
                bundleData: selectedBundle,
                keyValue: hipNotifyData.keyValue,
                transactionId: hipNotifyData.transactionId,
                requestId: hipNotifyData.requestId,
                nonce: hipNotifyData.nonce,
                careContextReference: hipNotifyData.careContextReference,
                expiry: hipNotifyData.expireOn
            }
        await dispatch(dataPushToABDM(payload))
    }

    useEffect(() => {
        if(isDataPushedToABDM){
            setOpenDataPushSuccessPopup(true);
        }
    }, [isDataPushedToABDM])

    return (
        <>
            <div className="flex h-full flex-col gap-2 justify-between">
                <div className="flex flex-col gap-2">
                    <div>
                        <Button 
                            onClickCb={() => handleGenerate()} 
                            variant={BUTTON_VARIANTS.CONTAINED} 
                            customBtnClass={`px-4`}
                        >
                            Push To ABDM
                        </Button>
                    </div>
                    <Label fontSize="sm" fontWeight="bold" color="red-500">*Click on the button to push the bundle to abdm</Label>
                </div>                
            </div>
            <div className="flex justify-end">
                <Button 
                    onClickCb={handleCancle} 
                    variant={BUTTON_VARIANTS.OUTLINED}
                >Cancel</Button>
            </div>   
            <Modal
              title=""
              isOpen={openDataPushSuccessPopup}
              onConfirm={() => {
                setOpenDataPushSuccessPopup(false)
                onConfirm(ABHA_HEALTH_RECORD_NAVS.HEALTH_INFORMATION_NOTIFY)
              }}
              submitText="Okay"
          >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                <SuccessSvg/>
                <h1 className="font-bold text-lg">Bundle pushed successfully</h1>
            </div>
          </Modal>
        </>
    );
};

export default DataPushToABDM;