import React from "react";
import { FieldRow, formatDate, formatResourceType, renderObservationResult } from "./FHIRBundleViewer";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";

export const DiagnosticReport = ({ resource, bundle }) => {
    const entries = bundle?.entry || [];
    const { status, category, code, specimen, result, conclusion } = resource;
  
    const getResourceByReference = (referenceId) => {
      const entry = entries.find((entry) => entry.fullUrl === referenceId);
      return entry ? entry.resource : null;
    };
  
    const specimenList = specimen?.map((item) => getResourceByReference(item.reference)) || [];
    const resultList = result?.map((item) => getResourceByReference(item.reference)) || [];
  
    return (
      <div className="mb-6 p-4 border border-gray-200 rounded">
          <Heading type={HEADING.H3}className="text-lg font-semibold mb-2">
              {formatResourceType(resource.resourceType)}
          </Heading>
          <div className="grid grid-cols-2 gap-2">
              <FieldRow label="Status" value={status} />
              <FieldRow label="Category" value={category?.text} />
              <FieldRow label="Code" value={code?.text} />
              <FieldRow label="Conclusion" value={conclusion} />
          </div>
          {specimenList?.length > 0 &&
          <div className="mt-3">
              <Heading type={HEADING.H4} className="font-medium mb-1">Specimen</Heading>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                  
              {specimenList?.map((spec, index) =>
                  spec ? (
                      
                      <div key={index} className="mb-2 p-2 border border-gray-200 rounded">
                          <FieldRow label="Specimen Type" value={spec.type?.text} />
                          <FieldRow
                          label="Recieved Date"
                          value={formatDate(spec.receivedTime)}
                          />
                      </div>
                  ) : null
              )}
              </div>
          </div>}
              {resultList?.length > 0 &&
          <div className="mt-3">
              <Heading type={HEADING.H4} className="font-medium mb-1">Results</Heading>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
              {resultList.map((res, index) => (
                  <div key={index} className="mb-2 p-2 border border-gray-200 rounded">
                  {renderObservationResult(res)}
                  </div>
              ))}
              </div>
          </div>
          }
      </div>
    );
  };