import React, { useEffect, useRef } from "react";
import { componentKey as PatientDashboardComponentKey, setGeneratedTokenData } from "../../PatientDashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import { addNotifications } from "../../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../../components/common-components/toaster/MetToaster";
import "./SandClock.css";
import { generateToken } from "../../PatientDashboardSaga";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Icons from "../../../../../components/icons/Icons";
const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

export const SandClock = () => {
  return (
    <div className="flex justify-center items-center h-48 w-48">
      <div className="relative flex flex-col justify-between items-center w-24 h-40 border-4 border-black rounded-lg overflow-hidden">
        <div className="sand-top w-16 h-4 bg-sand rounded-md absolute top-4 sandMovement"></div>
        <div className="sand-bottom w-16 h-4 bg-sand rounded-md absolute bottom-4 sandMovement"></div>
      </div>
    </div>
  );
};

function GenerateTokenPopup ({close, onConfirm}) {
    const { patientData, generatedTokenData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    
    const handleGenerate = async () => {
        await dispatch(generateToken({id: patientData.uuid}))
    }

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(socketURL);
        }
        const socket = socketRef.current;
        
        socket.on("connect", () => {
            startEmitting();
            setTimeout(async () => {
                await handleGenerate()
            }, 2000)
        });

        socket.on("getLinkToken", (data) => {
            if(data){
                console.log("socket data (getLinkeToken)", data);
                if(data?.statusCode !== 200){
                    dispatch(addNotifications({ message: "Something went wrong. Please try again some time!", variant: TOASTER_VARIANT.ERROR }))
                    return;
                }
                dispatch(addNotifications({ message: "Details Fetched successfully!", variant: TOASTER_VARIANT.SUCCESS }))
                dispatch(setGeneratedTokenData(data));
                stopEmitting();
            }
        });


        return () => {
            if (socket) {
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    const startEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getLinkToken");
        }
    };

    const stopEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getLinkToken", { action: "stop" });
        }
    };
    return(
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
            <div className="bg-white shadow-lg rounded-lg p-4 w-full max-w-2xl max-h-screen overflow-y-auto">
                {!generatedTokenData && 
                    <div className="flex justify-end items-center border-b pb-3 hover:cursor-pointer" onClick={close}>
                        <Icons iconName={"closeIcon"}/>
                    </div>
                }
                {generatedTokenData ?  
                    (<div className="flex flex-col gap-4 p-2">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <div className="flex justify-center items-center w-16 h-16 bg-green-500 rounded-full">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <h1 className="font-bold text-lg text-green-600">Token Generated Successfully</h1>
                        </div>
                        <div className="flex justify-end items-end">
                            <Button 
                                onClickCb={() => onConfirm()} 
                                variant={BUTTON_VARIANTS.CONTAINED} 
                                customBtnClass={`px-4`}
                            >
                                Next
                            </Button>
                        </div>
                    </div> ):(
                    <div className="flex flex-col gap-4 justify-center items-center p-2">
                        <SandClock/>
                        <h1 className="font-bold text-lg">Please wait to generate token</h1>
                    </div>
                    )
                }
                
            </div>
        </div>
    )
}
export default GenerateTokenPopup;