import React from "react";
import { FieldRow, formatResourceType } from "./FHIRBundleViewer";
import Button from "../../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../../components/common-components/button/Constants";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";

export function DocumentReference ({ resource }) {
    const attachment = resource.content?.[0]?.attachment;
    const fileData = attachment?.data;
    const fileUrl = attachment?.url;
    const contentType = attachment?.contentType || 'application/pdf';
  
    const handleView = () => {
      if (fileUrl) {
        window.open(fileUrl, '_blank', 'noopener,noreferrer');
      } else if (fileData) {
        const byteCharacters = atob(fileData);
        const byteNumbers = new Uint8Array(byteCharacters.length).map((_, i) =>
          byteCharacters.charCodeAt(i)
        );
        const blob = new Blob([byteNumbers], { type: contentType });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      }
    };
  
    return (
      <div className="mb-6 p-4 border border-gray-200 rounded">
          <Heading type={HEADING.H3}className="text-lg font-semibold mb-2">
              {formatResourceType(resource.resourceType)}
          </Heading>
          <FieldRow label="Status" value={resource.status} />
          <FieldRow label="Type" value={resource.type?.text} />
          {(fileData || fileUrl) && (
              <div className="mt-3">
              <Button
                  variant={BUTTON_VARIANTS.CONTAINED}
                  onClickCb={handleView}
                  className="px-3 py-1 border border-gray-200 rounded focus:outline-none"
              >
                  View Document
              </Button>
              </div>
          )}
      </div>
    );
  }