import React from "react";
import { FieldRow, formatDate, formatResourceType } from "./FHIRBundleViewer";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";

export function Appointment ({resource}) {
    const {appointmentType, start, status, specialty, serviceType, } = resource;
    return (
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md border border-gray-300">
            <Heading type={HEADING.H3}className="text-xl font-bold text-gray-800 mb-4">
                {formatResourceType(resource?.resourceType)}
            </Heading>
            <div>
                <FieldRow label={"Status"} value={status}/>
                <FieldRow label={"Start"} value={formatDate(start)}/>
                <FieldRow label={"AppointmentType"} value={appointmentType?.text}/>
            </div>
            {specialty?.length > 0 &&
                <div className="mt-4 pl-4 border-l border-gray-300">
                    <Heading type={HEADING.H4} className="text-md font-medium text-gray-600 mb-2">Specialty</Heading>
                    <div className="flex gap-2">
                    {specialty?.map((data, idx) => (
                        <div key={idx} className="p-2 bg-white rounded border border-gray-200">
                            <p>{data?.text}</p>
                        </div>
                    ))}
                    </div>
                </div>
            }
            {serviceType?.length > 0 && serviceType[0].text &&
                <div className="mt-4 pl-4 border-l border-gray-300">
                    <Heading type={HEADING.H4} className="text-md font-medium text-gray-600 mb-2">Service Type</Heading>
                    <div className="flex gap-2">
                    {serviceType?.map((data, idx) => (
                        <div key={idx} className="p-2 bg-white rounded border border-gray-200">
                            <p>{data?.text}</p>
                        </div>
                    ))}
                    </div>
                </div>
            }
        </div>
    )
}