import React from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { FORM_FIELDS_FOR_SERVICE_REQUEST, SERVICE_REQUEST_INTENT_OPTIONS, SERVICE_REQUEST_STATUS_OPTIONS } from "../Constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";

const fields = [
	{ fieldName: FORM_FIELDS_FOR_SERVICE_REQUEST.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_SERVICE_REQUEST.INTENT, isRequired: true, isDropdown: true },
];

const validationSchema = getValidationSchema(fields);

const ServiceRequest = ({ open, close, onConfirm }) => {

    return (
        <>
            <Formik
                initialValues={{
                    [FORM_FIELDS_FOR_SERVICE_REQUEST.STATUS]: "",
                    [FORM_FIELDS_FOR_SERVICE_REQUEST.INTENT]: "",
                    [FORM_FIELDS_FOR_SERVICE_REQUEST.TEXT]: "",
                    [FORM_FIELDS_FOR_SERVICE_REQUEST.CODE]: ""
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const data = {
                        resourceType: "ServiceRequest",
                        status: values[FORM_FIELDS_FOR_SERVICE_REQUEST.STATUS].value,
                        intent: values[FORM_FIELDS_FOR_SERVICE_REQUEST.INTENT].value,
                        text: values[FORM_FIELDS_FOR_SERVICE_REQUEST.TEXT],
                        code : {
                            text: values[FORM_FIELDS_FOR_SERVICE_REQUEST.CODE]
                        }
                    };
                    onConfirm((prev) => [...prev, { type: `Service Request ${prev.length + 1}`, data: data }]);
                    close();
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <ModalComponent
                        open={open}
                        title={"Add Service Request"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED} 
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={() => close()}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                        customBodyClasses="overflow-visible" 
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <SelectDropdown
                                label="Service Request Status"
                                name={FORM_FIELDS_FOR_SERVICE_REQUEST.STATUS}
                                customClasses="w-full"
                                isRequired={true}
                                placeholder="Select"
                                value={values[FORM_FIELDS_FOR_SERVICE_REQUEST.STATUS]}
                                options={SERVICE_REQUEST_STATUS_OPTIONS}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(FORM_FIELDS_FOR_SERVICE_REQUEST.STATUS, selectedOption)
                                }
                            />
                            <SelectDropdown
                                label="Service Request Intent"
                                name={FORM_FIELDS_FOR_SERVICE_REQUEST.INTENT}
                                customClasses="w-full"
                                isRequired={true}
                                placeholder="Select"
                                value={values[FORM_FIELDS_FOR_SERVICE_REQUEST.INTENT]}
                                options={SERVICE_REQUEST_INTENT_OPTIONS}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(FORM_FIELDS_FOR_SERVICE_REQUEST.INTENT, selectedOption)
                                }
                            />
                            <Input
                                label="Text Summary"
                                placeholder="Text summary of the resource, for human interpretation"
                                name={FORM_FIELDS_FOR_SERVICE_REQUEST.TEXT}
                                value={values[FORM_FIELDS_FOR_SERVICE_REQUEST.TEXT]}
                                onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_SERVICE_REQUEST.TEXT, e.target.value)}
                            />
                            <Input
                                label="Code/Text"
                                placeholder="What is being requested/ordered"
                                name={FORM_FIELDS_FOR_SERVICE_REQUEST.CODE}
                                value={values[FORM_FIELDS_FOR_SERVICE_REQUEST.CODE]}
                                onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_SERVICE_REQUEST.CODE, e.target.value)}
                            />
                        </div>
                    </ModalComponent>
                )}
            </Formik>
        </>
    );
};

export default ServiceRequest;
