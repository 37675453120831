import React, { useEffect, useState } from "react";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Label from "../../../../../components/common-components/label/Label";
import { useDispatch, useSelector } from "react-redux";
import { healthInformationNotify } from "../../PatientDashboardSaga";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";


const HealthInformationNotify = ({handleCancle}) => {
    const { patientData, hipNotifyData, isHINotified} = useSelector((state) => state[PatientDashboardComponentKey]);
    const [openHINotifySuccessPopup, setOpenHINotifySuccessPopup] = useState(false);
    const dispatch = useDispatch();

    const handleGenerate = async () => {
        const payload = 
            {         
                transactionId: hipNotifyData.transactionId,
                consentId:hipNotifyData.consentId,
                careContextReference: hipNotifyData.careContextReference,
            }
        
        await dispatch(healthInformationNotify(payload))
    }

    useEffect(() => {
        if(isHINotified){
            setOpenHINotifySuccessPopup(true);
        }
    }, [isHINotified])

    const handleSubmit = () => {
        setOpenHINotifySuccessPopup(false)
        handleCancle()
    }

    return (
        <>
            <div className="flex h-full flex-col gap-2 justify-between">
                <div className="flex flex-col gap-2">
                    <div>
                        <Button 
                            onClickCb={() => handleGenerate()} 
                            variant={BUTTON_VARIANTS.CONTAINED} 
                            customBtnClass={`px-4`}
                        >
                            Notify HI
                        </Button>
                    </div>
                    <Label fontSize="sm" fontWeight="bold" color="red-500">*Click on the button to notify health information</Label>
                </div>                
            </div>
            <div className="flex justify-end">
                <Button 
                    onClickCb={handleCancle} 
                    variant={BUTTON_VARIANTS.OUTLINED}
                >Cancel</Button>
            </div>   
            <Modal
              title=""
              isOpen={openHINotifySuccessPopup}
              onConfirm={() => {
                handleSubmit()
              }}
              submitText="Okay"
          >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                <SuccessSvg/>
                <h1 className="font-bold text-lg">Health information notified successfully</h1>
            </div>
          </Modal>
        </>
    );
};

export default HealthInformationNotify;