import React, { useState } from "react";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Input from "../../../../../components/common-components/input/Input";
import { ABHA_HEALTH_RECORD_VIEW_NAVS, FORM_FIELDS_FOR_CONSENT_INIT } from "../Constants";
import ConsentInitRequestPopup from "./consentInitRequestPopup";
import * as Yup from 'yup';

const endOfToday = new Date();
endOfToday.setHours(23, 59, 59, 999);

const validationSchema = Yup.object().shape({
  [FORM_FIELDS_FOR_CONSENT_INIT.FROM]: Yup.date()
    .max(endOfToday, "'From' date cannot be in the future")
    .required("From date is required"),
  [FORM_FIELDS_FOR_CONSENT_INIT.TO]: Yup.date()
    .min(
      Yup.ref(FORM_FIELDS_FOR_CONSENT_INIT.FROM),
      "'To' date cannot be earlier than 'From' date"
    )
    .max(endOfToday, "'To' date cannot be in the future")
    .required("To date is required"),
});


const ConsentInitRequest = ({ onConfirm, handleCancle }) => {
    const [isLoadingOpen, setIsLoadingOpen] = useState(false)

    const clickNext = () => {
        onConfirm(ABHA_HEALTH_RECORD_VIEW_NAVS.CONSENT_STATUS)
    }

    return (
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_CONSENT_INIT.FROM]: '',
                [FORM_FIELDS_FOR_CONSENT_INIT.TO]: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setIsLoadingOpen(true);
            }}
        >
            {({ values, setFieldValue, handleSubmit, touched }) => (
                <div className="h-full flex flex-col gap-8 p-4">
                    <div className="flex flex-col justify-center items-center">
                        <h2 className="text-2xl font-bold text-gray-800 text-center">
                            Consent Initialization Request
                        </h2>
                        <p className="text-gray-600 text-center">
                            Please select the date range for your health information request. Ensure all fields are filled in correctly.
                        </p>
                    </div>

                <form
                    onSubmit={handleSubmit} className="h-full flex flex-col justify-between"  >
                    <div className="flex gap-4">
                        <Input
                            label="From"
                            isRequired={true}
                            type="date"
                            name={FORM_FIELDS_FOR_CONSENT_INIT.FROM}
                            value={values[FORM_FIELDS_FOR_CONSENT_INIT.FROM]}
                            onChangeCb={(e) =>
                                setFieldValue(FORM_FIELDS_FOR_CONSENT_INIT.FROM, e.target.value)
                            }
                        />
                        <Input
                            label="To"
                            isRequired={true}
                            type="date"
                            name={FORM_FIELDS_FOR_CONSENT_INIT.TO}
                            value={values[FORM_FIELDS_FOR_CONSENT_INIT.TO]}
                            onChangeCb={(e) =>
                                setFieldValue(FORM_FIELDS_FOR_CONSENT_INIT.TO, e.target.value)
                            }
                        />
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button 
                            onClickCb={handleCancle} 
                            variant={BUTTON_VARIANTS.OUTLINED}
                        >Cancel</Button>
                        <Button
                            type="submit"
                            variant={BUTTON_VARIANTS.CONTAINED}
                            customBtnClass="px-6 py-2 "
                        >
                            Submit
                        </Button>
                    </div>
                </form>
                { isLoadingOpen && 
                    <ConsentInitRequestPopup 
                        onConfirm={clickNext} 
                        close={() => setIsLoadingOpen(false)}
                        data={{from: values[FORM_FIELDS_FOR_CONSENT_INIT.FROM], to: values[FORM_FIELDS_FOR_CONSENT_INIT.TO]}}
                    />
                }
            </div>
            )}
        </Formik>
            
    );
};

export default ConsentInitRequest;
