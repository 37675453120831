import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_NAVS, INVOICE_RECORD_FIELDS, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createInvoiceBundle } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import Invoice from "./Invoice";
import HealthRecordHelper from "../utils/utils";

const fields = [
	{ fieldName: INVOICE_RECORD_FIELDS.STATUS, isRequired: true, isDropdown: true },
    { fieldName: INVOICE_RECORD_FIELDS.TITLE, isRequired: true },
    { fieldName: INVOICE_RECORD_FIELDS.DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields);
const InvoiceRecord = ({onConfirm, handleCancle}) => {
    const { patientData, selectedBundle } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [reportsError, setReportsError] = useState("");
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if(selectedBundle) {
            setOpenSuccessPopup(true);
        }
    },[selectedBundle])
        
    useEffect(() => {
        if(invoiceList.length > 0) setReportsError("");
    },[invoiceList])

    const structurePayloadWithAllData = (values) => {
        const payload = {
            status: values[INVOICE_RECORD_FIELDS.STATUS].value,
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            date: values[INVOICE_RECORD_FIELDS.DATE],
            title: values[INVOICE_RECORD_FIELDS.TITLE],
            section: {
                text: values[INVOICE_RECORD_FIELDS.SUMMARY],
                entry: invoiceList.map((item) => item.data),
            }
        };
        return payload;
    };

    return (
        <>
            <Formik
                initialValues={{
                    [INVOICE_RECORD_FIELDS.STATUS]:"",
                    [INVOICE_RECORD_FIELDS.DATE]:"",
                    [INVOICE_RECORD_FIELDS.TITLE]:"",
                    [INVOICE_RECORD_FIELDS.SUMMARY]:"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if(invoiceList.length === 0) {
                    setReportsError("Atleast 1 report is required.");
                    return;
                    }
                    const payload = HealthRecordHelper.removeEmptyFields(structurePayloadWithAllData(values));
                    dispatch(createInvoiceBundle(payload));
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="space-y-4 h-full w-full flex flex-col justify-between">
                        <div className="grid grid-cols-2 gap-2">
                            <SelectDropdown
                                label="Composition Status"
                                name={INVOICE_RECORD_FIELDS.STATUS}
                                isRequired={true}
                                placeholder="Select"
                                customClasses="w-full"
                                value={values[INVOICE_RECORD_FIELDS.STATUS]}
                                options={RECORD_STATUS}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue(INVOICE_RECORD_FIELDS.STATUS, selectedOption)
                                }
                            />
                            <Input
                                label="Prescription Editing Time"
                                name={INVOICE_RECORD_FIELDS.DATE}
                                type="datetime-local"
                                isRequired={true}
                                value={values[INVOICE_RECORD_FIELDS.DATE]}
                                onChangeCb={(e) => setFieldValue(INVOICE_RECORD_FIELDS.DATE, e.target.value)}
                            />
                            <Input
                                label="Title"
                                placeholder="Human Readable name/title"
                                type="text"
                                name={INVOICE_RECORD_FIELDS.TITLE}
                                isRequired={true}
                                value={values[INVOICE_RECORD_FIELDS.TITLE]}
                                onChangeCb={(e) => setFieldValue(INVOICE_RECORD_FIELDS.TITLE, e.target.value)}
                            />
                            <Input
                                label="Summary"
                                placeholder="Text summary of the section, for human interpretation"
                                type="text"
                                name={INVOICE_RECORD_FIELDS.SUMMARY}
                                value={values[INVOICE_RECORD_FIELDS.SUMMARY]}
                                onChangeCb={(e) => setFieldValue(INVOICE_RECORD_FIELDS.SUMMARY, e.target.value)}
                            />
                            
                            <div className="flex flex-col gap-2 col-span-2">
                                <ItemManager
                                    title={<>Invoice entry <span className="text-red-500">*</span></>} 
                                    items={invoiceList}
                                    setItems={setInvoiceList}
                                    setIsPopupOpen={setIsPopupOpen}
                                    customHeight="52"
                                />
                                {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                            </div>
                            
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                Submit
                            </Button>
                        </div>
                    </form>
                )}
                </Formik>
                {isPopupOpen && 
                    <Invoice 
                        onConfirm={setInvoiceList} 
                        close={() => setIsPopupOpen(false)}
                    />
                }
                <Modal
                    title=""
                    isOpen={openSuccessPopup}
                    onConfirm={() => {
                        setOpenSuccessPopup(false)
                        onConfirm(ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM)
                    }}
                    submitText="Okay"
                >
                    <div className="flex flex-col gap-4 justify-center items-center p-2">
                        <SuccessSvg/>
                        <h1 className="font-bold text-lg">Invoice Bundle created successfully</h1>
                    </div>
                </Modal>
        </>
    );
};

export default InvoiceRecord;
