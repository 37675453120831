import React, { useEffect, useRef } from "react";
import { componentKey as PatientDashboardComponentKey, setCareContextLinkingData, setIscareContextLinked } from "../../PatientDashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import { addNotifications } from "../../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../../components/common-components/toaster/MetToaster";
import "./SandClock.css";
import { careContextLinking } from "../../PatientDashboardSaga";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { SandClock } from "./GenerateTokenPopup";
import { v4 as uuidv4 } from 'uuid';
const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

function ContextLinkingPopup ({onErrorCancle,close, onConfirm, data}) {
    const { generatedTokenData, careContextLinkingData, iscareContextLinked, patientData } = useSelector((state) => state[PatientDashboardComponentKey]);
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    
    const handleGenerate = async () => {
        const careContext = data.careContextList?.map((item) => ({
            referenceNumber: `visit-${uuidv4()}`,
            display: item.type,
        }));
    
        const payload = {
            abhaAddress: generatedTokenData?.abhaAddress,
            abhaNumber: generatedTokenData?.abhaNumber.replace(/\D/g, ""),
            hiType: data.hiType.split(" ").join(""),
            careContext,
            xToken: generatedTokenData?.linkToken,
            patientId: patientData.uuid,
        };
        if(!payload.abhaAddress){
            dispatch(addNotifications({ message: "Token is expired!", variant: TOASTER_VARIANT.ERROR }));
            onErrorCancle()
            return;
        }
        await dispatch(careContextLinking(payload));
    }

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(socketURL);
        }
        const socket = socketRef.current;
        
        socket.on("connect", () => {
            //startEmittingHipNotify();
            startEmittingHipInitiate();
            setTimeout(async () => {
                await handleGenerate()
            }, 2000)
        });

        socket.on("hipInitiate", (data) => {
            if (data) {
                console.log("socket data (hipInitiate)", data);
                if (data.statusCode === 200) {
                    dispatch(addNotifications({ message: "Care Context Linking is successful", variant: TOASTER_VARIANT.SUCCESS }));
                    dispatch(setIscareContextLinked(true));
                    stopEmittingHipInitiate();
                    startEmittingHipNotify();
                }
            }
        });

        socket.on("hipNotify", (data) => {
            if (data) {
                console.log("socket data (hipNotify)", data);
                dispatch(setCareContextLinkingData(data));
                stopEmittingHipNotify();
            }
        });

        return () => {
            if (socket) {
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    const startEmittingHipInitiate = () => {
        if (socketRef.current) {
            socketRef.current.emit("hipInitiate");
        }
    };

    const startEmittingHipNotify = () => {
        if (socketRef.current) {
            socketRef.current.emit("hipNotify");
        }
    };

    const stopEmittingHipInitiate = () => {
        if (socketRef.current) {
            socketRef.current.emit("hipInitiate", { action: "stop" });
        }
    };

    const stopEmittingHipNotify = () => {
        if (socketRef.current) {
            socketRef.current.emit("hipNotify", { action: "stop" });
        }
    };

    return(
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
            <div className="bg-white shadow-lg rounded-lg p-4 w-full max-w-2xl max-h-screen overflow-y-auto">
                {!careContextLinkingData && 
                    <div className="flex justify-end items-center border-b pb-3">
                        <button onClick={close} className="text-gray-600 hover:text-black text-2xl">
                            ✕
                        </button>
                    </div>
                }
                {iscareContextLinked ?  
                    (<div className="flex flex-col gap-4 p-2">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <div className="flex justify-center items-center w-16 h-16 bg-green-500 rounded-full">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <h1 className="font-bold text-lg text-green-600">Care Context Linking is successfull</h1>
                            {!careContextLinkingData && <h2>Waiting for patient to pull the record</h2>}
                        </div>
                        {careContextLinkingData &&
                            <div className="flex justify-end items-end">
                                <Button 
                                    onClickCb={() => onConfirm()} 
                                    variant={BUTTON_VARIANTS.CONTAINED} 
                                    customBtnClass={`px-4`}
                                >
                                    Next
                                </Button>
                            </div>
                        }
                    </div> ):(
                    <div className="flex flex-col gap-4 justify-center items-center p-2">
                        <SandClock/>
                        <h1 className="font-bold text-lg">Please wait to link Care Context</h1>
                    </div>
                    )
                }
                
            </div>
        </div>
    )
}
export default ContextLinkingPopup;