import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Button from '../../../../components/common-components/button/Button';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent';
import Icons from '../../../../components/icons/Icons';
import Input from '../../../../components/common-components/input/Input';
import { getValidationSchema } from '../../../../libs/formsUtils';

const AddSpecificType = ({ open, close , label, placeholder, name, setList }) => {

    const fields = [
        { fieldName: name, isRequired: true },
    ];
    
    const validationSchema = getValidationSchema(fields);
	return (
        <Formik
            initialValues={{
                [name]:"",
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
                setList((prev) => [...prev, {type: values[name], data: values[name]}])
                close();
            }}
        >	
            {({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <ModalComponent
                            open={open}
                            title={`Add ${label}`}
                            footerButton={
                                <Button
                                    type="submit"
                                    variant={BUTTON_VARIANTS.CONTAINED}
                                    customBtnClass="text-met-primary"
                                    onClickCb={() => {
                                    }}
                                >
                                    Submit
                                </Button>
                            }
                            icon={<Icons iconName="closeIcon" />}
                            close={close}
                            customClasses="w-[800px]"
                        >
                            <div className="p-2">
                                <div className="flex column-gap-10px items-center pt-6">
                                    <div className="flex-1">
                                        <div>
                                            <Input
                                                label={label}
                                                placeholder={placeholder}
                                                isRequired={true}
                                                name={name}
                                                value={values[name]}
                                                onChangeCb={(e) => {
                                                    setFieldValue(name,e.target.value);	
                                                }}
                                                onBlurCb={handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    </Form>
                );
            }}
        </Formik>
			
		
	);
};

export default AddSpecificType;
