import React, { useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_HI_TYPES, ABHA_HEALTH_RECORD_NAVS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import Label from "../../../../../components/common-components/label/Label";
import Input from "../../../../../components/common-components/input/Input";
import Modal from "../Modal";
import ContextLinkingPopup from "./ContextLinkingPopup";
import { useDispatch } from "react-redux";
import { setCareContextLinkingData, setIscareContextLinked, setRecordType } from "../../PatientDashboardSlice";

const CareContextLinking = ({ onConfirm, handleCancle }) => {
    const [careContextList, setCareContextList] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoadingOpen, setIsLoadingOpen] = useState(false)
    const [newItem, setNewItem] = useState("");
    const [error, setError] = useState("");
    const [careContextError, setCareContextError] = useState("");
    const dispatch = useDispatch()

    const handleAddItem = () => {
        if (!newItem.trim()) {
            setError("Item name cannot be empty.");
            return;
        }
        setCareContextList((prev) => [...prev, {type:newItem.trim()}]);
        setNewItem("");
        setError("");
        setCareContextError("")
        setIsPopupOpen(false);
    };

    const hiTypeOptions = [
        { label: "Select HI Type", value: "Select HI Type" },
        ...Object.entries(ABHA_HEALTH_RECORD_HI_TYPES).map(([key, value]) => ({
        label: key.replace(/_/g, " "),
        value: value,
        })),
    ];

    const validate = (values) => {
        const errors = {};
        if (!values.hiType || values.hiType.value === "Select HI Type") {
        errors.hiType = "Please select a valid Health Information Type.";
        }
        return errors;
    };

    const clickNext = (values) => {
        dispatch(setRecordType(values.hiType.value))
        onConfirm(ABHA_HEALTH_RECORD_NAVS.HIP_NOTIFY)
    }
    return (
        <>
            <Formik
            initialValues={{
                hiType: { label: "Select HI Type", value: "Select HI Type" },
            }}
            validate={validate}
            onSubmit={(values) => {
                if (careContextList.length === 0) {
                    setCareContextError("Atleast one Care Context is required.");
                    return;
                }
                dispatch(setCareContextLinkingData(null));
                dispatch(setIscareContextLinked(false));
                setIsLoadingOpen(true)
            }}
            >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <>
                    <form onSubmit={handleSubmit} className="space-y-4 h-full flex flex-col justify-between">
                        <div className="flex flex-col gap-4">
                            <SelectDropdown
                                label="Select Health Information Type"
                                name="hiType"
                                customClasses="w-1/2"
                                value={values.hiType}
                                options={hiTypeOptions}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("hiType", selectedOption)
                                }
                            />
                            <div className="flex flex-col gap-2">
                                <ItemManager
                                    title="Care Contexts"
                                    items={careContextList}
                                    setItems={setCareContextList}
                                    setIsPopupOpen={setIsPopupOpen}
                                    customHeight="96"
                                />
                                {careContextError && <Label fontSize="sm" fontWeight="bold" color="red-500">{careContextError}</Label>}
                            </div>
                            
                        </div>
                        <div className="flex gap-2 justify-end">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED}> Submit</Button>
                        </div>
                    </form>
                    {isLoadingOpen && 
                        <ContextLinkingPopup 
                            onErrorCancle={handleCancle} 
                            close={() =>
                                setIsLoadingOpen(false)
                            } 
                            data={{
                                hiType: values.hiType.value,
                                careContextList: careContextList,
                            }} 
                            onConfirm={() => clickNext(values)}
                        />
                    }
                </>
            )}
            </Formik>
            <Modal
                title="Add Care Contexts"
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onConfirm={handleAddItem}
            >
                <Input
                    placeholder="Enter care context name"
                    value={newItem}
                    onChangeCb={(e) => setNewItem(e.target.value)}
                />
                {error && <p className="text-red-500 text-sm">{error}</p>}
            </Modal>
            
        </>
    );
};

export default CareContextLinking;
