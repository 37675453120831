import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  ABHA_HEALTH_RECORD_NAVS, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import DiagnosticLabReport from "./diagnosticLabReport";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createDiagnosticBundle } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import DocumentReference from "./DocumentReference";
import HealthRecordHelper from "../utils/utils";

const DiagnosticRecord = ({onConfirm, handleCancle}) => {
  const { patientData, selectedBundle } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [reportsList, setReportsList] = useState([]);
  const [docRefList, setDocRefList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [docRefError, setDocRefError] = useState("");
  const [sectionError, setSectionError] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openDocRefPopup, setOpenDocRefPopup] = useState(false);
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
      errors.compositionStatus = "Please select composition status.";
    }
    return errors;
  };

  useEffect(() => {
    if(reportsList.length !== 0 || docRefList !== 0) setSectionError("");
    if(reportsList.length !== 0) setReportsError("");
    if(docRefList.length !== 0) setDocRefError("");

  },[reportsList, docRefList])

  useEffect(() => {
    if(selectedBundle){
      setOpenSuccessPopup(true);
    }
  },[selectedBundle])

  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values.compositionStatus.value,
        subject: HealthRecordHelper.getStructurePatientData(patientData),
        entry: [
          reportsList.length > 0
            ? { diagnosticLabReportEntry: reportsList.map((item) => item.labReport) }
            : null,
          docRefList.length > 0
            ? { documentReferenceEntry: docRefList.map((item) => item.data) }
            : null,
        ].filter(Boolean),              
      };
      return payload;
  };

  return (
      <>
          <Formik
              initialValues={{
                  compositionStatus:{ label: "Select Status", value: "Select Status" },
                  recordType: { label: "Select Record Type", value: "Select Record Type" },
              }}
              validate={validate}
              onSubmit={(values) => {
                if(reportsList.length === 0 && docRefList.length === 0) {
                    setSectionError("At least one lab report or document reference is required.");
                    return;
                }
                const payload = structurePayloadWithAllData(values);
                dispatch(createDiagnosticBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="space-y-4 h-full w-full flex flex-col justify-between">
                      <div className="flex h-full flex-col gap-2">
                          <SelectDropdown
                              label="Composition Status"
                              name="compositionStatus"
                              customClasses="w-1/2"
                              isRequired={true}
                              value={values.compositionStatus}
                              options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                              onChangeCb={(selectedOption) =>
                                  setFieldValue("compositionStatus", selectedOption)
                              }
                          />
                          <div className="flex flex-col gap-4 border-2 border-gray-200 rounded-md p-4">
                            <Heading type={HEADING.H2}>Section<span className="text-red-500">*</span></Heading>
                            <div className="flex flex-col gap-2">
                              <ItemManager
                                  title="Lab report"
                                  items={reportsList}
                                  setItems={setReportsList}
                                  setIsPopupOpen={(value) => {
                                    if(reportsList.length < 1) setIsPopupOpen(value);
                                    else setReportsError("Only one report is allowed");
                                    
                                  }}
                                  customHeight="52"
                              />
                              {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                            </div>
                            <div className="flex flex-col gap-2">
                              <ItemManager
                                  title="Document reference"
                                  items={docRefList}
                                  setItems={setDocRefList}
                                  setIsPopupOpen={(value) => {
                                    if(docRefList.length < 1){
                                        setOpenDocRefPopup(value)
                                    }else setDocRefError("Only one document reference is allowed")
                                    
                                  }}
                                  customHeight="52"
                              />
                              {docRefError && <Label fontSize="sm" fontWeight="bold" color="red-400">{docRefError}</Label>}
                            </div>
                          </div>
                          {sectionError && <Label fontSize="sm" fontWeight="bold" color="red-500">{sectionError}</Label>}
                      </div>
                      <div className="flex justify-end gap-4">
                        <Button 
                            onClickCb={handleCancle} 
                            variant={BUTTON_VARIANTS.OUTLINED}
                        >Cancel</Button>
                        <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                            Submit
                        </Button>
                      </div>
                  </form>
              )}
          </Formik>
          {isPopupOpen && 
            <DiagnosticLabReport 
              onConfirm={setReportsList} 
              close={() => setIsPopupOpen(false)}
            />
          }
          {openDocRefPopup &&
            <DocumentReference
              open={openDocRefPopup}
              onConfirm={setDocRefList}
              close={() => setOpenDocRefPopup(false)}
            />
          }
          <Modal
              title=""
              isOpen={openSuccessPopup}
              onConfirm={() => {
                setOpenSuccessPopup(false)
                onConfirm(ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM)
              }}
              submitText="Okay"
          >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                <SuccessSvg/>
                <h1 className="font-bold text-lg">Diagnostic Bundle created successfully</h1>
            </div>
          </Modal>
      </>
  );
};

export default DiagnosticRecord;
