import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_FIELDS, OBSERVATION_STATUS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import ItemManager from "../ItemManager";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { v4 as uuidv4 } from 'uuid';
import Icons from "../../../../../components/icons/Icons";

const Observation = ({ onConfirm, close, setObservationError, isNested = false }) => {
    const [observationList, setObservationList] = useState([]);
    const [isObservationPopupOpen, setIsObservationPopupOpen] = useState(false);

    const validate = (values) => {
        const errors = {};
        if (values.observationStatus.value === "Select Status") {
            errors.observationStatus = "Status is required.";
        }
        if (!values.code) {
            errors.code = "Code is required.";
        }
        if (!values.result) {
            errors.result = "Result is required.";
        }
        return errors;
    };

    return (
        <>
            <Formik
                initialValues={{
                    observationStatus: { label: "Select Status", value: "Select Status" },
                    code: "",
                    result: ""
                }}
                validate={validate}
                onSubmit={(values) => {
                    onConfirm((prev) => [
                        ...prev,
                        {
                            type: `Observation ${prev.length + 1}`,
                            data: {
                                resourceType: ABHA_HEALTH_RECORD_FIELDS.OBSERVATION,
                                status: values.observationStatus.value,
                                code: values.code,
                                valueQuantity: {
                                    value: values[`result`],
                                    unit: values[`resultUnit`]
                                },
                                hasMember: observationList.map((item) => item.data)
                            }
                        }
                    ]);
                    if (setObservationError) {
                        setObservationError("");
                    }
                    close();
                }}
            >
                {({ values, handleSubmit, setFieldValue, handleBlur }) => (
                    <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                        <div className="bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl max-h-screen ">
                            <div className="flex justify-between items-center border-b pb-3">
                                <h2 className="text-lg font-semibold">Add Observation</h2>
                                <div className="flex justify-end items-center pb-3 hover:cursor-pointer" onClick={close}>
                                    <Icons iconName={"closeIcon"}/>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-2 mt-2">
                                    <SelectDropdown
                                        label="Select Status"
                                        isRequired={true}
                                        name="observationStatus"
                                        customClasses="w-full"
                                        value={values.observationStatus}
                                        options={[{ label: "Select Status", value: "Select Status" }, ...OBSERVATION_STATUS]}
                                        onChangeCb={(selectedOption) =>
                                            setFieldValue("observationStatus", selectedOption)
                                        }
                                    />

                                    <Input
                                        label="Code"
                                        placeholder="Enter Type of observation (code / type) Ex: Blood Pressure"
                                        name="code"
                                        isRequired={true}
                                        value={values.code}
                                        onChangeCb={(e) => setFieldValue("code", e.target.value)}
                                        onBlurCb={handleBlur}
                                    />


                                    <Input
                                        label="Value"
                                        isRequired={true}
                                        placeholder="Enter Actual result"
                                        name="result"
                                        value={values.result}
                                        onChangeCb={(e) => setFieldValue("result", e.target.value)}
                                    />

                                    <Input
                                        label="Value Units"
                                        placeholder={`Please provide units for value. Ex: mm Hg`}
                                        name={`resultUnit`}
                                        value={values[`resultUnit`]}
                                        onChangeCb={(e) => setFieldValue(`resultUnit`, e.target.value)}
                                    />
                                    {isNested &&
                                        <ItemManager
                                            key={"Related resource that belongs to the Observation group"}
                                            title={"Related resource that belongs to the Observation group"}
                                            items={observationList}
                                            setItems={setObservationList}
                                            setIsPopupOpen={setIsObservationPopupOpen}
                                            customHeight="44"
                                            customClass={"col-span-2"}
                                        />
                                    }
                                </div>
                                <div className="flex justify-end">
                                    <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
            {isObservationPopupOpen && 
                <Observation 
                    key={`nestedObservation_${uuidv4()}`} 
                    onConfirm={setObservationList} 
                    close={() => setIsObservationPopupOpen(false)}
                    items={observationList}
                />
            }
        </>
    );
};

export default Observation;
