import React from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { GOAL_STATUS_OPTIONS } from "../Constants";

const Goal = ({ open, close, onConfirm }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.goalStatus || values.goalStatus.value === "Select Status") {
            errors.goalStatus = "Please select a goal status.";
        }
        if (!values.goalDescription) {
            errors.goalDescription = "Please provide a description for the goal.";
        }
        return errors;
    };

    return (
        <>
            <Formik
                initialValues={{
                    goalStatus: { label: "Select Status", value: "Select Status" },
                    goalDescription: "",
                    goalTargetMeasure: "",
                }}
                validate={validate}
                onSubmit={(values) => {
                    const goalData = {
                        resourceType: "Goal",
                        status: values.goalStatus.value,
                        description: {
                            text: values.goalDescription,
                        },
                        target: {
                            text: values.goalTargetMeasure,
                        },
                    };
                    onConfirm((prev) => [...prev, { type: `Goal Report ${prev.length + 1}`, data: goalData }]);
                    close();
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <ModalComponent
                        open={open}
                        title={"Add Goal"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={() => close()}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                        customBodyClasses="overflow-visible" 
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <SelectDropdown
                                label="Goal Status"
                                name="goalStatus"
                                customClasses="w-full"
                                isRequired={true}
                                value={values.goalStatus}
                                options={[{ label: "Select Status", value: "Select Status" }, ...GOAL_STATUS_OPTIONS]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("goalStatus", selectedOption)
                                }
                            />
                            <Input
                                label="Description"
                                placeholder="Enter code or text describing goal"
                                name="goalDescription"
                                isRequired={true}
                                value={values.goalDescription}
                                onChangeCb={(e) => setFieldValue("goalDescription", e.target.value)}
                            />
                            <Input
                                label="Target"
                                placeholder="Enter target outcome for the goal"
                                name="goalTargetMeasure"
                                value={values.goalTargetMeasure}
                                onChangeCb={(e) => setFieldValue("goalTargetMeasure", e.target.value)}
                            />
                        </div>
                    </ModalComponent>
                )}
            </Formik>
        </>
    );
};

export default Goal;
