import React from "react";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";
import { FieldRow, formatDate, renderObservationResult } from "./FHIRBundleViewer";

export const MedicationRequest = ({ resource, bundle }) => {
    const getResourceByReference = (referenceId) => {
      const entry = bundle?.entry?.find((entry) => entry.fullUrl === referenceId);
      return entry?.resource;
    };
  
    const resultList =
      resource?.reasonReference?.map((item) => getResourceByReference(item.reference)) || [];
    return (
      <div className="mb-6 p-4 border border-gray-200 rounded">
          <Heading type={HEADING.H2} className="text-lg font-semibold mb-2">
              {resource.resourceType}
          </Heading>
          <FieldRow label="Status" value={resource.status} />
          <FieldRow label="Intent" value={resource.intent} />
          <FieldRow
              label="Medication"
              value={resource.medicationCodeableConcept?.text}
          />
          <FieldRow label="Authored On" value={formatDate(resource.authoredon)} />
          {resultList?.length > 0 && resultList[0]?.status &&
              <div className="mt-3">
                  <Heading type={HEADING.H4} className="font-medium mb-1">Reason Reference</Heading>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {resultList?.length > 0 &&
                      resultList.map((res, index) => (
                      <div key={index} className="mb-2 p-2 border border-gray-200 rounded">
                          {renderObservationResult(res)}
                      </div>
                      ))}
                  </div>
              </div>
          }
          {resource.dosageInstruction && resource.dosageInstruction?.length > 0 && (
              <div className="mt-3">
              <Heading type={HEADING.H4} className="font-medium mb-1">Dosage Instructions</Heading>
              {resource.dosageInstruction.map((dosage, index) => (
                  <div key={index} className="mb-2 p-2 border-t border-gray-200">
                  <FieldRow label="Dosage" value={dosage.text} />
                  <FieldRow label="Route" value={dosage.route?.text} />
                  <FieldRow label="Method" value={dosage.method?.text} />
                  {dosage.additionalInstruction &&
                      dosage.additionalInstruction?.length > 0 && (
                      <div className="mt-1 p-2 border border-gray-200 rounded">
                          <h5 className="font-medium mb-1 ">
                          Additional Instructions
                          </h5>
                          {dosage.additionalInstruction.map((instruction, idx) => {
                          const instructionText =
                              instruction.text || instruction.coding?.[0]?.display;
                          return instructionText ? (
                              <FieldRow
                              key={idx}
                              label={`Instruction ${idx + 1}`}
                              value={instructionText}
                              />
                          ) : null;
                          })}
                      </div>
                      )}
                  </div>
              ))}
              </div>
          )}
      </div>
    );
  };