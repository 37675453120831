import React, { useEffect, useState } from 'react'
import Heading from '../../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../../components/common-components/heading/Constants'
import { ABHA_HEALTH_RECORD_NAVS } from '../Constants';
import GenerateLinkToken from './GenerateLinkToken';
import HipNotify from './HipNotify';
import DiagnosticRecord from '../Records/DiagnosticRecord';
import CareContextLinking from './CareContextLinking';
import AcknowledgeABDM from './AcknowledgeABDM';
import DataPushToABDM from './DataPushToABDM';
import HealthInformationNotify from './HealthInofrmationNotify';
import PrescriptionRecord from '../Records/PrescriptionRecord';
import { setCareContextLinkingData, setDiagnosticBundle, setGeneratedTokenData, setHipNotifyData, setIsABDMAcknowledged, setIscareContextLinked, setIsDataPushedToABDM, setIsHINotified, setIsTokenGenerated, setRecordType, setSelectedBundle } from '../../PatientDashboardSlice';
import { useDispatch } from 'react-redux';
import DischargeSummary from '../Records/DischargeSummary';
import HealthDocumentRecord from '../Records/HealthDocumentRecord';
import NavItem from '../NavItem';
import OpConsultation from '../Records/OpConsultationRecord';
import WellnessRecord from '../Records/WellnessRecord';
import ImmunizationRecord from '../Records/ImmunizationRecord';
import InvoiceRecord from '../Records/InvoiceRecord';

function AddABHAhealthRecord({handleABHAhealthRecord}) {
    const [navigationItems, setNavigationItems] = useState([ABHA_HEALTH_RECORD_NAVS.GENERATE_TOKEN]);
    const dispatch = useDispatch()

    const handleNavigation = (step) => {
        setNavigationItems(prevState => [...prevState, step])
    }

    useEffect(() => {
        return () => {
            dispatch(setIsTokenGenerated(false))
            dispatch(setGeneratedTokenData(null))
            dispatch(setIscareContextLinked(false))
            dispatch(setCareContextLinkingData(null))
            dispatch(setRecordType(""))
            dispatch(setIsHINotified(false))
            dispatch(setHipNotifyData(null))
            dispatch(setIsABDMAcknowledged(false))
            dispatch(setDiagnosticBundle(null))
            dispatch(setIsDataPushedToABDM(false))
            dispatch(setSelectedBundle(null))
        }
    }, [dispatch])

    return (
        <div className='flex flex-col w-full h-full gap-8 p-2'>
            <div className='flex flex-col gap-2'>
                <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>Add ABHA Health Record</Heading>
                <div className='flex gap-2 flex-wrap'>
                    {navigationItems.map((item, index) =>(
                        <NavItem key={`${item}${index}`} text={item} index={index} length = {navigationItems.length}/>
                    ))}
                </div>
            </div>
            <div className='z-50 w-full h-full border-2 rounded-md border-gray-200 py-4 px-4 flex flex-col justify-between'>
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.GENERATE_TOKEN && 
                    <GenerateLinkToken onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.CARE_CONTEXT_LINKING && 
                    <CareContextLinking onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.HIP_NOTIFY && 
                    <HipNotify onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.ACKNOWLEDGE_ABDM && 
                    <AcknowledgeABDM onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM && 
                    <DataPushToABDM onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.HEALTH_INFORMATION_NOTIFY && 
                    <HealthInformationNotify handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.DIAGNOSTIC_REPORT &&
                    <DiagnosticRecord onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.DISCHARGE_SUMMARY &&
                    <DischargeSummary onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.HEALTH_DOCUMENT_RECORD &&
                    <HealthDocumentRecord onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.IMMUNIZATION_RECORD &&
                    <ImmunizationRecord onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.OP_CONSULTATION &&
                    <OpConsultation onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.PRESCRIPTION &&
                    <PrescriptionRecord onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.WELLNESS_RECORD &&
                    <WellnessRecord onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
                {navigationItems[navigationItems.length - 1] === ABHA_HEALTH_RECORD_NAVS.INVOICE &&
                    <InvoiceRecord onConfirm={handleNavigation} handleCancle={handleABHAhealthRecord}/>
                }
            </div>
        </div>
    )
}

export default AddABHAhealthRecord;