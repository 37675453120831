import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { IMMUNIZATION_RECOMMENDATION_FIELDS} from "../Constants";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import ItemManager from "../ItemManager";
import AddSpecificType from "../AddSpecificType";
const fields = [
	{ fieldName: IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS, isRequired: true },
];
const validationSchema = getValidationSchema(fields);

const ImmunizationRecommendation = ({ open, close, onConfirm }) => {

    const [vaccineList, setVaccineList] = useState([]);
    const [contraindicatedVaccineList, setContraindicatedVaccineList] = useState([]);
    const [forecastReasonList, setForecastReasonList] = useState([]);

    const [isVaccinePopupOpen, setIsVaccinePopupOpen] = useState(false);
    const [iscontraindicatedVaccinePopupOpen, setIsContraindicatedVaccinePopupOpen] = useState(false);
    const [isForecastReasonPopupOpen, setIsForecastReasonPopupOpen] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    [IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS]: "",
                    [IMMUNIZATION_RECOMMENDATION_FIELDS.FORECAST_REASON]: "",
                    [IMMUNIZATION_RECOMMENDATION_FIELDS.TARGET_DISEASE]: "",
                    [IMMUNIZATION_RECOMMENDATION_FIELDS.VACCINE_CODE]: "",
                    [IMMUNIZATION_RECOMMENDATION_FIELDS.CONTRAINDICATED_VACCINE_CODE]: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const mapList = (list) => list.length ? list.map(({ data }) => ({ text: data })) : null;
                
                    const data = {
                        ...(values[IMMUNIZATION_RECOMMENDATION_FIELDS.TARGET_DISEASE]?.trim() && { targetDisease: { text: values[IMMUNIZATION_RECOMMENDATION_FIELDS.TARGET_DISEASE] } }),
                        ...(values[IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS]?.trim() && { forecastStatus: { text: values[IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS] } }),
                        ...(vaccineList.length && { vaccineCode: mapList(vaccineList) }),
                        ...(forecastReasonList.length && { forecastReason: mapList(forecastReasonList) }),
                        ...(contraindicatedVaccineList.length && { contraindicatedVaccineCode: mapList(contraindicatedVaccineList) })
                    };
                    onConfirm((prev) => [...prev, { type: `Recommendation ${prev.length + 1}`, data }]);
                    setSubmitting(false);
                    close();
                }}
                
                
            >
                {({ values, errors, setFieldValue, handleSubmit, handleBlur }) => (
                    
                    <ModalComponent
                        open={open}
                        title={"Add Immunization recommendation"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={close}
                        customClasses="w-full sm:w-[90%] md:w-[60%]"
                        customBodyClasses="overflow-visible"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <Input
                                label="Forecast Status"
                                placeholder="Enter text representation for forecase status"
                                name={IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS}
                                value={values[IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS]}
                                isRequired={true}
                                onChangeCb={(e) =>
                                    setFieldValue(IMMUNIZATION_RECOMMENDATION_FIELDS.FORCAST_STATUS, e.target.value)
                                }
                                onBlurCb={handleBlur}
                            />
                            <Input
                                label="Target Disease code/text"
                                placeholder="Please enter target disease code/text"
                                name={IMMUNIZATION_RECOMMENDATION_FIELDS.TARGET_DISEASE}
                                value={values[IMMUNIZATION_RECOMMENDATION_FIELDS.TARGET_DISEASE]}
                                onChangeCb={(e) =>
                                    setFieldValue(IMMUNIZATION_RECOMMENDATION_FIELDS.TARGET_DISEASE, e.target.value)
                                }
                                onBlurCb={handleBlur}
                            />
                            <ItemManager
                                title="Forecast Reason"
                                items={forecastReasonList}
                                setItems={setForecastReasonList}
                                setIsPopupOpen={setIsForecastReasonPopupOpen}
                                customHeight="52"
                                customClass={'col-span-2'}
                            />
                            <ItemManager
                                title="Vaccine Code/Text"
                                items={vaccineList}
                                setItems={setVaccineList}
                                setIsPopupOpen={setIsVaccinePopupOpen}
                                customHeight="52"
                                customClass={'col-span-2'}
                            />
                            <ItemManager
                                title="Contraindicated Vaccine Code/Text"
                                items={contraindicatedVaccineList}
                                setItems={setContraindicatedVaccineList}
                                setIsPopupOpen={setIsContraindicatedVaccinePopupOpen}
                                customHeight="52"
                                customClass={'col-span-2'}
                            />
                        </div>
                    </ModalComponent>
                )}
            </Formik>
            {isVaccinePopupOpen && 
                <AddSpecificType
                    open={isVaccinePopupOpen}
                    close={() => setIsVaccinePopupOpen(false)}
                    label={"Vaccine code/text"}
                    placeholder={"Vaccine or vaccine group recommendation applies to"}
                    setList={setVaccineList}
                    name={IMMUNIZATION_RECOMMENDATION_FIELDS.VACCINE_CODE}
                />
            }
            {iscontraindicatedVaccinePopupOpen && 
                <AddSpecificType
                    open={iscontraindicatedVaccinePopupOpen}
                    close={() => setIsContraindicatedVaccinePopupOpen(false)}
                    label={"Contraindicated Vaccine code/text"}
                    placeholder={"Vaccine which is contraindicated to fulfill the recommendation"}
                    setList={setContraindicatedVaccineList}
                    name={IMMUNIZATION_RECOMMENDATION_FIELDS.CONTRAINDICATED_VACCINE_CODE}
                />
            }
            {isForecastReasonPopupOpen && 
                <AddSpecificType
                    open={isForecastReasonPopupOpen}
                    close={() => setIsForecastReasonPopupOpen(false)}
                    label={"Forecast Reason"}
                    placeholder={"Vaccine administration status reason"}
                    setList={setForecastReasonList}
                    name={IMMUNIZATION_RECOMMENDATION_FIELDS.FORECAST_REASON} 
                />
            }
        </>
    );
};

export default ImmunizationRecommendation;
