import React from 'react'

export default function NavItem({text, index, length}) {
    return (
        <div
            className= {`flex items-center h-8 ${index+1 === length ? "bg-met-primary" : "bg-green-400"} text-white`}
            style={{
                clipPath: 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)',
            }}
        >
            <span className="ml-2 mr-6">{text}</span>
        </div>
    );
}