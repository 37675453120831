import React, { useEffect, useState, useRef } from "react";
import { ABHA_HEALTH_RECORD_NAVS } from "../Constants";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Label from "../../../../../components/common-components/label/Label";
import { useDispatch, useSelector } from "react-redux";
import { componentKey as PatientDashboardComponentKey, setHipNotifyData, setCareContextLinkingData } from "../../PatientDashboardSlice";
import HipNotifyPopup from "./HipNotifyPopup";
import { io } from 'socket.io-client';
import Modal from "../Modal";
import { SandClock } from "./GenerateTokenPopup";
import { addNotifications } from "../../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../../components/common-components/toaster/MetToaster";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

const HipNotify = ({onConfirm, handleCancle }) => {
  const { careContextLinkingData } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [openHipNotifyPopup, setOpenHipNotifyPopup] = useState(false);
  const [openHipPullRecordPopup, setOpenHipPullRecordPopup] = useState(false);
  const [openPullSuccessPopup, setOpenPullSuccessPopup] = useState(false);
  
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  

  const handleInitiateHipNotify = async () => {
    setOpenHipPullRecordPopup(true);
    dispatch(setCareContextLinkingData(null));
    if (!socketRef.current) {
        socketRef.current = io(socketURL);
    }
    const socket = socketRef.current;
    
    socket.on("connect", () => {
        startEmitting();
    });

    socket.on("hipNotify", (data) => {
      if (data) {
        if(data.statusCode === 200){
          console.log("socket data (hipNotify)", data);
          dispatch(setCareContextLinkingData(data));
          setOpenHipPullRecordPopup(false);
          setOpenPullSuccessPopup(true);
          stopEmitting();
        } else {
          dispatch(addNotifications({ message: "Something went wrong. Please try again some time!", variant: TOASTER_VARIANT.ERROR }))
          setOpenHipPullRecordPopup(false);
        }
          
      }
  });
    return () => {
        if (socket) {
            socket.disconnect();
            socketRef.current = null;
        }
    };
  };

  const startEmitting = () => {
      if (socketRef.current) {
          socketRef.current.emit("hipNotify");
      }
  };

  const stopEmitting = () => {
      if (socketRef.current) {
          socketRef.current.emit("hipNotify", { action: "stop" });
      }
      setOpenHipPullRecordPopup(false);
  };

  const handleHIPNotify = () => {
    dispatch(setHipNotifyData(null));
    setOpenHipNotifyPopup(true)
  }
  
  const clickNext = () => {
    setOpenHipNotifyPopup(false)
    onConfirm(ABHA_HEALTH_RECORD_NAVS.ACKNOWLEDGE_ABDM)
  }
  return (
      <>
        <div className="flex flex-col gap-6 p-4 bg-white rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-800">HIP Record Management</h2>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-4 flex-wrap">
              <div className="flex flex-col items-start">
                <Button
                  onClickCb={handleHIPNotify}
                  variant={BUTTON_VARIANTS.CONTAINED}
                  customBtnClass="px-6 py-2 hover:bg-blue-700 text-white rounded-md"
                >
                  Initiate HIP Notify
                </Button>
                <Label fontSize="sm" fontWeight="bold" color="gray-600">
                  Click to send a notification to HIP for data sync.
                </Label>
              </div>
              <div className="flex flex-col items-start">
                <Button
                  onClickCb={handleInitiateHipNotify}
                  variant={BUTTON_VARIANTS.TEXT}
                  customBtnClass="px-6 py-2 bg-green-600 hover:bg-green-700 text-white rounded-md"
                >
                  Initiate latest Pull Record
                </Button>
                <Label fontSize="sm" fontWeight="bold" color="gray-600">
                  If the initiate HIP notify is taking long, try pulling the record and Initiate HIP notify.
                </Label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
            <Button 
                onClickCb={handleCancle} 
                variant={BUTTON_VARIANTS.OUTLINED}
            >Cancel</Button>
        </div>   
        {openHipNotifyPopup && <HipNotifyPopup close={() => setOpenHipNotifyPopup(false)} onConfirm={clickNext}/>}
        <Modal
            title="HIP pull record"
            isOpen={openHipPullRecordPopup}
            onClose={() => setOpenHipPullRecordPopup(false)}
        >
          <div className="flex flex-col gap-4 justify-center items-center p-2">
              <SandClock/>
              <h1 className="font-bold text-lg">Give access in mobile to pull the record</h1>
          </div>
        </Modal>
        <Modal
            title=""
            isOpen={openPullSuccessPopup}
            onConfirm={() => setOpenPullSuccessPopup(false)}
            submitText="Okay"
        >
          <div className="flex flex-col gap-4 justify-center items-center p-2">
              <SuccessSvg/>
              <h1 className="font-bold text-lg">Record pulled successfully</h1>
          </div>
        </Modal>
      </>
  );
};

export default HipNotify;