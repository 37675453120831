import React, { useEffect, useState } from "react";
import { ABHA_HEALTH_RECORD_NAVS } from "../Constants";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import Label from "../../../../../components/common-components/label/Label";
import GenerateTokenPopup from "./GenerateTokenPopup";

const GenerateLinkToken = ({onConfirm, handleCancle}) => {
    const [openGenerateTokenPopup, setOpenGenerateTokenPopup] = useState(false)

    const closePopup = () => {
        setOpenGenerateTokenPopup(false)
    }
    const clickNext = () => {
        setOpenGenerateTokenPopup(false)
        onConfirm(ABHA_HEALTH_RECORD_NAVS.CARE_CONTEXT_LINKING)
    }

    return (
        <>
            <div className="flex h-full flex-col gap-2 justify-between">
                <div className="flex flex-col gap-2">
                    <div>
                        <Button 
                            onClickCb={() => setOpenGenerateTokenPopup(true)} 
                            variant={BUTTON_VARIANTS.CONTAINED} 
                            customBtnClass={`px-4`}
                        >
                            Generate
                        </Button>
                    </div>
                    <Label fontSize="sm" fontWeight="bold" color="red-500">*Click on the Generate button to generate link token</Label>
                </div>             
                <div className="flex justify-end">
                    <Button 
                        onClickCb={handleCancle} 
                        variant={BUTTON_VARIANTS.OUTLINED}
                    >Cancel</Button>
                </div>   
            </div>
            {openGenerateTokenPopup && <GenerateTokenPopup close={closePopup} onConfirm={clickNext}/>}
        </>
    );
};

export default GenerateLinkToken;