import React from "react";
import { FieldRow, formatResourceType } from "./FHIRBundleViewer";
import Heading from "../../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../../components/common-components/zoom/components/heading/constants/constants";

export const Procedure = ({ resource }) => {
    const { status, bodySite, code, outcome, reasonCode } = resource;
    return(
        <div className="mb-6 p-4 bg-white rounded-lg shadow-md border border-gray-300">
            <Heading type={HEADING.H3}className="text-xl font-bold text-gray-800 mb-4">
                {formatResourceType(resource.resourceType)}
            </Heading>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-2">
                <FieldRow label="Status" value={status} />
                <FieldRow label="Code" value={code?.text} />
                <FieldRow label="Outcome" value={outcome?.text} />
            </div>
            {bodySite?.length > 0 &&
                <div className="mt-4 pl-4 border-l border-gray-300">
                <Heading type={HEADING.H4} className="text-md font-medium text-gray-600 mb-2">Body Site</Heading>
                <div className="flex gap-2">
                {bodySite?.map((site, idx) => (
                    <div key={idx} className="p-2 bg-white rounded border border-gray-200">
                        <p>{site?.text}</p>
                    </div>
                ))}
                </div>
            </div>
            }
        </div>
    )
}